import React, { useEffect, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select';
import { PageTitle } from '../../../../../_metronic/layout/core';
import CallFor from '../../../../routing/CallFor';
import { AUTH, GET, POST, REG_AUTH } from '../../../../../constants/global';

import CategoryModal from '../modal/CategoryModal';
import ProductModel from '../modal/ProductModel';
import ApiErrorMessage from '../../../../hooks/apiErrorMessage';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';



const AddUser: React.FC = () => {
    const navigate = useNavigate()
    const [businessCategory, setBusinessCategory] = useState<any[]>([]);
    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [modalCategory, setModalCategory] = useState(false);
    const [product, setProduct] = useState([]);
    const [productId, setProductId] = useState([]);
    const [modalProduct, setModalProduct] = useState(false);
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        emailId: "",
        mobileNo: "",
        sellProduct: "",
        firstName_error: "",
        lastName_error: "",
        emailId_error: "",
        mobileNo_error: "",
        sellProduct_error: ""
    })

    type UserSubmitForm = {
        firstName: string;
        lastName: string;
        emailId: string;
        mobileNo: number;
        sellProduct: any;
    }


    const { handleSubmit, control } = useForm<UserSubmitForm>({})
    useEffect(() => {
        getBusinessCategory();
    }, []);

    useEffect(() => {
        console.log("categoryId:", categoryId)
    }, [categoryId]);

    useEffect(() => {
        if (productId) {
            values.sellProduct = productId.join(",")
        }
    }, [productId]);
    const getBusinessCategory = async () => {
        const response = await CallFor('api/admin/v1/businesses/category', GET, null, REG_AUTH)
        // const response = await fetch('http://192.168.7.119:7075/api/admin/v1/plans/PlaneAmount',{
        //     method:'GET'
        // })
        if (response.status === 200) {
            const res = await response.json();
            const states = res.data.filter((item: any) => item.status === 'ACTIVE').map(
                (d: { id: any; planName: any; amount: any; status: any }) => ({
                    value: d.id,
                    label: d.planName,
                })
            );
            setBusinessCategory(states);
        }
    }



    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const validationField = [event.target.name] + '_error'
        setValues({ ...values, [event.target.name]: event.target.value, [validationField]: '' });
    }


    const categorySelectHandler = (selectId: any) => {

        //localStorage.removeItem("subcategoryProduct");
        //localStorage.removeItem("salesCategory");
        console.log("ddd", selectId);
        setCategory((prevSelected: any) => {
            console.log(prevSelected);
            if (prevSelected) {

                let isExist
                isExist = (prevSelected).find((item: any) => item?.id === selectId?.id)
                if (isExist) {
                    return (prevSelected)?.filter((item: any) => item?.id !== selectId?.id)
                } else {
                    return [...prevSelected, selectId]
                }
            } else {
                return prevSelected
            }

        }
        );
    };
    const productSelectHandler = (selectId: any) => {

        //localStorage.removeItem("subcategoryProduct");
        //localStorage.removeItem("salesCategory");
        console.log("ddd", selectId);
        setCategory((prevSelected: any) => {
            console.log(prevSelected);
            if (prevSelected) {

                let isExist
                isExist = (prevSelected).find((item: any) => item?.id === selectId?.id)
                if (isExist) {
                    return (prevSelected)?.filter((item: any) => item?.id !== selectId?.id)
                } else {
                    return [...prevSelected, selectId]
                }
            } else {
                return prevSelected
            }

        }
        );
    };



    const clickCategoryHandler = () => {
        if (modalCategory === true) {
            setModalCategory(false)
        }
        setModalCategory(true)
    }
    const clickProductHandler = () => {
        if (modalProduct === true) {
            setModalProduct(false)
        }
        setModalProduct(true)
    }

    const CheckErrorCode = async (code: string, errorSubCode: string) => {
        const subCode: number = parseInt(errorSubCode);
        const error = await ApiErrorMessage(subCode, code);
        console.log(error);
        alert("Error Code: " + error);
    }


    const ErrorMsg =
    {
        firstName: "Enter First Name",
        lastName: "Enter Last Name",
        emailId: "Enter E-mail Id",
        mobileNo: "Enter Mobile No",
        sellProduct: "Select What product"
    }

    const validationSchema = () => {


        if (values.firstName.trim() === '' && values.lastName.trim() === '' && values.emailId.trim() === '' && values.mobileNo.trim() === '' && values.sellProduct.trim() === '') {
            setValues((prev: any) => ({ ...prev, firstName_error: ErrorMsg.firstName, lastName_error: ErrorMsg.lastName, emailId_error: ErrorMsg.emailId, mobileNo_error: ErrorMsg.mobileNo, sellProduct_error: ErrorMsg.sellProduct }))
            return false
        }
        if (values.firstName.trim() === '') {
            setValues((prev: any) => ({ ...prev, firstName_error: ErrorMsg.firstName }))
            return false
        }
        if (values.lastName.trim() === '') {
            setValues((prev: any) => ({ ...prev, lastName_error: ErrorMsg.lastName }))
            return false
        }
        if (values.emailId.trim() === '') {
            setValues((prev: any) => ({ ...prev, emailId_error: ErrorMsg.emailId }))
            return false
        }
        if (values.mobileNo.trim() === '') {
            setValues((prev: any) => ({ ...prev, mobileNo_error: ErrorMsg.mobileNo }))
            return false
        }
        else {
            if (values.mobileNo.length >= 0 && !values.mobileNo.match(/[0-9]/)) {
                setValues((prev: any) => ({ ...prev, mobileNo_error: "Only Alphabets Allow" }))
                return false
            }
            if (values.mobileNo.trim().length < 2) {
                setValues((prev: any) => ({ ...prev, mobileNo_error: "Minimum 2 character required" }))
                return false
            }
            // if (values.ClientContactNumber.trim().length > 10) {
            //     setValues((prev: any) => ({ ...prev, ClientContactNumber_error: "ConatctNo is Invalid" }))
            //     return false
            // }

            if (values.mobileNo.match(/[a-zA-Z]/)) {
                setValues((prev: any) => ({ ...prev, mobileNo_error: "Contact No Must Be in digit only " }))
                return false
            }
            if (values.mobileNo.trim().length < 10) {
                setValues((prev: any) => ({ ...prev, mobileNo_error: "Enter 10 digit ContactNo" }))
                return false
            }
            if (values.mobileNo.trim().length > 10) {
                setValues((prev: any) => ({ ...prev, mobileNo_error: "Mobile No is Invalid" }))
                return false
            }
        }

        if (values.sellProduct.trim() === '') {
            setValues((prev: any) => ({ ...prev, sellProduct_error: ErrorMsg.sellProduct }))
            return false
        }
        return true
    }
    const submitHandler = async () => {
        if (validationSchema()) {

            const jsonData = {
                firstName: values.firstName,
                lastName: values.lastName,
                emailId: values.emailId,
                mobileNo: values.mobileNo,
                sellProduct: productId,
                userLogo: null,
                userCoverOther: null,
                userCoverVisitingCard: null,
                userCoverWorkspace: null
            }
            console.log("submitHandler", jsonData);
            const data = JSON.stringify(jsonData);
            const response = await CallFor(
                `api/admin/v1/users`,
                POST,
                data,
                AUTH
            )
            try {
                if (response?.status === 201) {
                    toast.success("Create User Successfully");
                    setValues((prev: any) => ({
                        ...prev,
                        firstName: "",
                        lastName: "",
                        emailId: "",
                        mobileNo: "",
                        sellProduct: "",
                        firstName_error: "",
                        lastName_error: "",
                        emailId_error: "",
                        mobileNo_error: "",
                        sellProduct_error: ""
                    }))
                    navigate('/mis/user')

                }
                else if (response.status === 400) {
                    const dataRes = await response.json();
                    if (dataRes.error?.subcode) {

                        const subCode: number = parseInt(dataRes.error.subcode);
                        const error1 = await ApiErrorMessage(subCode, dataRes.error.code);

                        toast.success(error1);
                    }
                    else {
                        dataRes?.error?.errors?.forEach(async (details: { field: any; message: string }) => {
                            const subCode = parseInt(details?.message);
                            let errorMsg = await ApiErrorMessage(subCode, ""); // Await the promise here
                            setValues((prev: any) => ({ ...prev, [`${details?.field}_error`]: errorMsg }));
                        });
                    }
                }
                else {
                    const dataRes = await response.json();
                    console.log(dataRes);
                }



            } catch (err) {
                console.log(err);
            }
            // }
            // else {
            //     console.log("validationSchema", validationSchema());
            // }
        }
    }


    return (
        <div>
            <div>
                <PageTitle >Create User</PageTitle>
                <form onSubmit={handleSubmit(submitHandler)} >
                    <div className='card mb-5 p-5'>
                        <div className='row'>
                            <div className="mb-3 col-xxl-3 col-lg-4">
                                <label className="form-label">First Name *</label>
                                <input
                                    type="text"
                                    name='firstName'
                                    // className="form-control"
                                    className={`form-control ${values?.firstName_error === "" ? '' : 'is-invalid'} `}
                                    placeholder="Enter First Name"
                                    value={values.firstName}
                                    onChange={(e) => handleChange(e)}
                                />
                                <p id='error_firstName' className='errormsg' >{values?.firstName_error}</p>
                            </div>
                            <div className="mb-3 col-xxl-3 col-lg-4">
                                <label className="form-label">Last Name *</label>
                                <input
                                    type="text"
                                    name='lastName'
                                    // className="form-control"
                                    className={`form-control ${values?.lastName_error === "" ? '' : 'is-invalid'} `}
                                    placeholder="Enter Last Name"
                                    value={values.lastName}
                                    onChange={(e) => handleChange(e)}
                                />
                                <p id='error_lastName' className='errormsg' >{values?.lastName_error}</p>
                            </div>

                            <div className="mb-3 col-xxl-3 col-lg-4">
                                <label className="form-label">Email*</label>
                                <input
                                    type="email"
                                    name='emailId'
                                    // className="form-control"
                                    className={`form-control ${values?.emailId_error === "" ? '' : 'is-invalid'} `}
                                    placeholder="Enter Email"
                                    value={values.emailId}
                                    onChange={(e) => handleChange(e)}
                                />
                                <p id='error_emailId' className='errormsg' >{values?.emailId_error}</p>
                            </div>

                            <div className="mb-3 col-xxl-3 col-lg-4">
                                <label className="form-label">Mobile*</label>
                                <input
                                    type="number"
                                    name='mobileNo'
                                    // className="form-control"
                                    className={`form-control ${values?.mobileNo_error === "" ? '' : 'is-invalid'} `}
                                    placeholder="Enter Mobile No"
                                    value={values.mobileNo}
                                    onChange={(e) => handleChange(e)}
                                />
                                <p id='error_mobileNo' className='errormsg' >{values?.mobileNo_error}</p>
                            </div>


                            <div className="mb-3 col-xxl-12 col-lg-12">
                                <label className="form-label">Business Category *</label>
                                {/* <div className="border border-gray-300 rounded-lg py-3 px-2 text-gray-800" > */}
                                <div className={`border border-dashed border-gray-300 rounded-lg min-h-80px w-full flex flex-wrap gap-2 p-2 cursor-pointer`} onClick={clickCategoryHandler}>
                                    {category && category.length === 0 ?
                                        <>{"Business Category"}</>
                                        :
                                        <>
                                            {category.map((category: any) => (
                                                <div key={category.id} className="bg-blue-100 py-2 pl-3 pr-1 h-8 border border-1B74E4 rounded-full text-sm text-gray-800 font-medium cursor-pointer w-fit flex items-center mb-1 mr-2 pr-2" >
                                                    {/* onClick={(e) => { e.stopPropagation(); categorySelectHandler(category) }}> */}
                                                    {category.name}
                                                    {/* <i className="fas fa-times w-5 h-5 ml-2 cursor-pointer mt-5px"></i> */}
                                                </div>
                                            ))}
                                        </>
                                    }
                                </div>

                                {/* </div> */}
                            </div>

                            <div className="mb-3 col-xxl-12 col-lg-12">
                                <label className="form-label">Product *</label>
                                {/* <div className="border border-gray-300 rounded-lg py-3 px-2 text-gray-800" > */}
                                <div className={`border border-dashed border-gray-300 rounded-lg min-h-80px w-full flex flex-wrap gap-2 p-2 cursor-pointer`} onClick={clickProductHandler}>
                                    {product && product.length === 0 ?
                                        <>{"Business Product"}</>
                                        :
                                        <>
                                            {product && product.map((prod: any) => (
                                                <div key={prod.productId} className="bg-blue-100 py-2 pl-3 pr-1 h-8 border border-1B74E4 rounded-full text-sm text-gray-800 font-medium cursor-pointer w-fit flex items-center mb-1 mr-2 pr-2">
                                                    {/* onClick={(e) => { e.stopPropagation(); productSelectHandler(prod) }}> */}
                                                    {prod.productName}
                                                    {/* <i className="fas fa-times w-5 h-5 ml-2 cursor-pointer mt-5px"></i> */}

                                                </div>
                                            ))}
                                        </>
                                    }
                                </div>
                                {/* </div> */}
                                <p id='error_sellProduct' className='errormsg' >{values?.sellProduct_error}</p>
                            </div>


                        </div>
                        <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                            <button type="submit" className="btn btn-primary">
                                Create
                            </button>
                        </div>

                        {/* <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                            <button type="button" className="btn btn-primary" onClick={() => { CheckErrorCode("AU", "1733") }}>
                                Create test
                            </button>
                        </div> */}
                    </div>
                </form>
            </div >

            <CategoryModal
                modalCategory={modalCategory}
                setModalCategory={setModalCategory}
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                setCategory={setCategory}
                setProductId={setProductId}
                setProduct={setProduct}
                nextBtnCall={clickProductHandler} />
            {categoryId && categoryId.length > 0 &&
                <ProductModel
                    category={category}
                    modalProduct={modalProduct}
                    setModalProduct={setModalProduct}
                    productId={productId}
                    setProductId={setProductId}
                    setProduct={setProduct} />
            }


        </div >
    )
}

export default AddUser;