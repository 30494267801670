import React, { useEffect, useRef, useState } from 'react'
import CallFor from '../../../../routing/CallFor';
import { AUTH, POST } from '../../../../../constants/global';
import { KTSVG } from '../../../../../_metronic/helpers';
import { convertResponsetojsonBig } from '../../../../../_metronic/layout/core/convertToBigJson';
import extractUniqueNumericToStrings from '../extractUniqueNumericToStrings';
import numberStringToIdArray from '../numberStringToIdArray';

export interface productOfSubCategoryType {
    productId: number,
    productName: string
}

const ProductModel = (props: any) => {
    const modalRefProduct = useRef<HTMLDivElement>(null);

    const [selectedProduct, setSelectedProduct] = useState<any | null>("");
    const [searchProductName, setSearchProductName] = useState("");
    const [categories, setCategories] = useState([]);
    const [searchCategoriesData, setSearchCategoriesData] = useState([]);
    const [productData, setProductData] = useState<any | null>("");
    const [showMsg, setShowMsg] = useState(false);


    const getProductData = async () => {

        console.log("getProductData :", props.category)
        const categoryIds = (props.category || '[]')?.map((it: any) => String(it?.id))
        const jsonData = { categoryId: categoryIds }
        const data = JSON.stringify(jsonData);

        let url = `api/admin/v1/businesses/sell/category`;
        if (props.isProductBuy) {
            url = `api/admin/v1/businesses/buy/categories`;
        }
        const response = await CallFor(
            url,
            POST,
            data,
            AUTH
        )
        try {
            if (response?.status === 200) {
                const data = await convertResponsetojsonBig(response);
                setCategories(data?.data?.content);
                setSearchCategoriesData(data?.data?.content);
                const count = data?.data?.content.length;
                if (count <= 0) {
                    setShowMsg(true)
                } else {
                    setShowMsg(false)
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const closeKpPlanModal = () => {

        modalRefProduct.current!.classList.remove("show")
        modalRefProduct.current!.style!.display = "none"
        modalRefProduct.current!.removeAttribute("aria-modal")
        modalRefProduct.current!.removeAttribute("role")
        modalRefProduct.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop');
        divElement[0].remove();
        props.setModalProduct(false);
    }
    useEffect(() => {

        if (props.productId !== "" && props.productId) {
            setSelectedProduct(props.productId)
        }
        else {
            setSelectedProduct([])
        }
        if (props.modalProduct === true) {

            props.setModalProduct(true)
            const divElement = document.createElement("div")
            divElement.classList.add("modal-backdrop")
            divElement.classList.add("fade")
            divElement.classList.add("show")
            const body = document.getElementById("kt_app_body")
            body?.appendChild(divElement)
            modalRefProduct.current!.style.display = "block"
            modalRefProduct.current!.style.paddingLeft = "0px"
            modalRefProduct.current!.classList.add("show")
            modalRefProduct.current!.setAttribute("aria-modal", "true")
            modalRefProduct.current!.setAttribute("role", "dialog")
            modalRefProduct.current!.removeAttribute("aria-hidden")

            getProductData();
        }
        //react-hooks/exhaustive-deps
    }, [props.modalProduct]);
    const searchHandler = (event: any) => {
        if (event.target.value) {
            setSearchProductName(event.target.value.toLowerCase())
            setCategories(
                searchCategoriesData?.filter((item: any) => item.name?.toLowerCase()?.includes(event.target.value.toLowerCase()))
            );
        } else {
            setSearchProductName("");
            setCategories(searchCategoriesData);
        }
    }
    // Function to check if there's a match
    const findMatches = (arr1: any[], arr2: { id: string }[]): boolean => {
        // Convert arr1 to a Set for faster lookups
        const setArr1 = new Set(arr1.map(item => item.toString())); // Ensure type consistency

        // Check if any categoryId in arr2 is present in setArr1
        const condition1 = arr2.some(item => setArr1.has(item.id));

        // Check if all elements in arr1 are present in categoryId of arr2
        const setArr2Ids = new Set(arr2.map(item => item.id));
        const condition2 = arr1.every(item => setArr2Ids.has(item.toString()));

        // Return true if both conditions are met
        return condition1 && condition2;
    };

    const nextBtn = () => {
        if (selectedProduct !== null) {
            // const data = findMatches(extractUniqueNumericToStrings(selectedProduct), numberStringToIdArray(categoryId))
            // if (data === false) {

            //     props.setProduct([]);
            //     props.setProductId("")
            // }
            // console.log("productData", productData)
            // console.log("productData2", selectedProduct, "|", props.isProductBuy ? "Buy" : "Sell")
            props.setProduct(productData);
            props.setProductId(selectedProduct)
            closeKpPlanModal();
        }
    }

    // const nextBtn = () => {
    //     if (selectedCategory !== null) {
    //         // const category = extractUniqueNumericToStrings(selectedCategory)

    //         const data = findMatches(extractUniqueNumericToStrings(selectedCategory), numberStringToIdArray(categoryId))
    //         if (data === false) {
    //             //console.log("categoryId : Update")
    //             localStorage.setItem('createBusinessCategoryId', selectedCategory)
    //             localStorage.setItem('categoryData', JSON.stringify(categoryData))
    //             localStorage.removeItem('sellData')
    //             localStorage.removeItem('selectedSellProducts')
    //         }
    //         localStorage.setItem('recentCategoryId', extractUniqueNumericToStrings(selectedCategory).join(','));
    //         localStorage.setItem('recentCategoryData', JSON.stringify(categoryData))
    //         router.replace('/addnewcompany/selectProductFromCategory')
    //     }
    // }



    const subCategorySelectHandler = (product: any) => {

        const selectId = product?.productId.toString();
        setSelectedProduct((prev: any) =>
            prev.includes(selectId)
                ? prev?.filter((id: any) => id?.toString() !== selectId?.toString())
                : [...selectedProduct, selectId?.toString()]
        );

        const isExists = productData.length > 0 ? productData.some((item: any) => item.productId === selectId) : false;
        if (isExists === true) {
            setProductData(productData.filter((item: any) => item?.productId !== product?.productId))
        }
        else {
            setProductData((prev: any) => [...prev, product])
        }

    };

    const getProductsBySubCategoryId = (subCategoryId: any) => {

        return categories.flatMap((category: any) =>
            category.subCategories
                .filter((subCategory: any) => subCategory.subCategoryId === subCategoryId)
                .flatMap((subCategory: any) => subCategory.products)

        );
    }

    const SelectAnyProducts = (subCategoryId: any) => {
        const products = getProductsBySubCategoryId(subCategoryId);
        return products.some((product: any) => selectedProduct.includes(product.productId.toString()));
    }

    const SelectAllProducts = (subCategoryId: any) => {
        const products = getProductsBySubCategoryId(subCategoryId);
        return products.every((product: any) => selectedProduct.includes(product.productId.toString()));
    }

    const subCategoryIdSelectHandler = (subCategoryId: any, isSelectAll: boolean) => {
        const products = getProductsBySubCategoryId(subCategoryId);
        products.map((product: any) => {
            const itemExists = selectedProduct.some((items: string) => items === product.productId.toString());
            if (isSelectAll === true && !itemExists) {
                setSelectedProduct((prevItems: string[]) => [...prevItems, product.productId]);

                const isExists = productData.length > 0 ? productData.some((item: any) => item.productId === product.productId) : false;
                if (isExists === false) {

                    setProductData((prev: any) => [...prev, product])
                }

                // if (props.isAnyFunctionCall === true) {
                //     props.functionNameSelectCall(product)
                // }
            }
            else if (isSelectAll === false && itemExists) {
                // console.log("deselected :", product)
                // console.log("deselected selectedProduct:", selectedProduct)
                // debugger;
                setSelectedProduct((prevItems: string[]) =>
                    prevItems.filter((item: string) => item !== product.productId.toString())
                );
                const isExists = productData.length > 0 ? productData.some((item: any) => item.productId === product.productId) : false;
                if (isExists === true) {
                    setProductData((prevProductData: any) =>
                        prevProductData.filter((item: any) => item?.productId !== product?.productId))
                }

            }
        })

    }
    return (
        <div className="modal fade" ref={modalRefProduct} tabIndex={-1} id="kt_modal_1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header p-4">
                        <h5 className="modal-title">Select your Product</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            // data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={closeKpPlanModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            {/* <form onSubmit={handleSubmit(submitHandler)}> */}
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="searchInput" className="form-label">
                                    {"Search Product"}
                                </label>
                                <div className="bgSearch flex justify-between items-center border-2 border-gray-300 rounded-lg pl-1 pr-3  w-7/12 w-full">
                                    <div className="relative w-full ">
                                        {/* <input type="text" id="floating_outlined_0.8931948193864565_undefined" className="block px-2.5 pb-2.5 pt-3 w-full text-lg bg-white  text-gray-900  border-gray-300 focus:border-blue-600  bg-transparent rounded-lg border appearance-none focus:outline-none focus:ring-0 peer border-0 focus:!border-0" placeholder="Search Product" value="" /> */}



                                        <input
                                            type="text"
                                            id="searchInput"
                                            placeholder={"Search Product"}
                                            className=" form-control border-0 focus:!border-0
                                            block px-2.5 pb-2.5 pt-3 w-full text-lg text-gray-900  border-gray-300-custom focus:border-blue-600  bg-transparent rounded-lg border appearance-none focus:outline-none focus:ring-0 peer "
                                            onChange={(e) => searchHandler(e)}
                                            value={searchProductName}
                                        />
                                    </div>
                                    {searchProductName.trim() && searchProductName.trim().length > 0 ? (
                                        <i
                                            className="fas fa-times text-1B74E4 w-5 h-5 ml-2 cursor-pointer mt-5px"
                                            onClick={(e) => searchHandler(e)}
                                        ></i>
                                    ) : (
                                        <i className="fas fa-search text-muted"></i>
                                    )}


                                </div>

                                <div className="h-290px overflow-auto">

                                    <>

                                        {!showMsg ? (searchCategoriesData?.length > 0 &&
                                            searchCategoriesData?.map((data: any) => {
                                                return (
                                                    <React.Fragment key={data?.categoryId?.toString()}>
                                                        {data?.subCategories?.map((product: any, i: number) => {
                                                            if (product?.products?.length > 0) {
                                                                return (
                                                                    product?.products?.length > 0 && (
                                                                        (
                                                                            // bg-#F9F9F9
                                                                            <div

                                                                                className="bgProducts min-h-36 rounded-lg p-2 mt-5"
                                                                                key={i}
                                                                            >
                                                                                {/* max-h-36  */}
                                                                                <div className="relative flex" >
                                                                                    <div className="font-bold text-gray-800 pb-1 float-left w-75">
                                                                                        {product.subCategoryName}
                                                                                    </div>
                                                                                    <div className="text-end w-25 float-right font14"  >
                                                                                        {SelectAllProducts(product.subCategoryId) === false &&
                                                                                            <a href="#" className="text-blue-600 cursor-pointer mr-2" onClick={() => { subCategoryIdSelectHandler(product.subCategoryId, true) }}>
                                                                                                {"Select All"}
                                                                                            </a>
                                                                                        }
                                                                                        {(SelectAllProducts(product.subCategoryId) === false && SelectAnyProducts(product.subCategoryId) === true) && (<>|</>)}
                                                                                        {SelectAnyProducts(product.subCategoryId) === true &&
                                                                                            <a href="#" className="text-blue-600 cursor-pointer ml-2" onClick={() => { subCategoryIdSelectHandler(product.subCategoryId, false) }}>
                                                                                                {"Deselect All"}
                                                                                            </a>}

                                                                                    </div>
                                                                                </div>

                                                                                <div className=" flex flex-wrap gap-2 min-h-[10px]">
                                                                                    {/* max-h-[92px] overflow-auto */}
                                                                                    {product?.products.map((product: productOfSubCategoryType) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    className={`chip-custom py-2 pl-3  rounded-full w-max text-sm text-gray-800 font-medium cursor-pointer hover:bg-gray-100 ${selectedProduct?.includes(
                                                                                                        product?.productId?.toString()
                                                                                                    )
                                                                                                        ? "bg-blue-100 pr-1 border-1B74E4 flex items-center"
                                                                                                        : "bg-white border border-gray-400 pr-3"
                                                                                                        }`}
                                                                                                    key={product?.productId}
                                                                                                    onClick={() => subCategorySelectHandler(product)}
                                                                                                >
                                                                                                    {product.productName}
                                                                                                    {selectedProduct?.includes(
                                                                                                        product?.productId?.toString()
                                                                                                    ) && (
                                                                                                            <span className='w-5 h-5 ml-2 pr-2 cursor-pointer'>
                                                                                                                <i className="fas fa-times cursor-pointer text-1B74E4"></i>

                                                                                                            </span>
                                                                                                        )}
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div >)
                                                                        // <ProductSection
                                                                        //     i={i}
                                                                        //     t={t}
                                                                        //     item={product}
                                                                        //     productDataOfCategory={categories}
                                                                        //     selectedProduct={selectedProduct}
                                                                        //     setSelectedProduct={setSelectedProduct}
                                                                        //     isAnyFunctionCall={false}
                                                                        //     functionNameSelectCall={""}
                                                                        //     functionNameDeselectCall={""}
                                                                        //     subCategorySelectHandler={subCategorySelectHandler}
                                                                        // />
                                                                    ));
                                                            }
                                                        })}

                                                    </React.Fragment >
                                                )
                                            }))
                                            : <div className="m-3 p-3 rounded-md text-center bg-gray-200 mt-10">
                                                {"Search for anything else!"}
                                            </div>
                                        }
                                    </>

                                </div>
                                {/* <div className="h-310px overflow-auto mt-2">
                                    {
                                        categories.length > 0 ?
                                            categories.map((item: any) => {
                                                return (
                                                    <div
                                                        className={`py-2 pl-3  mt-2 rounded-full w-max text-sm text-gray-800-custom font-medium cursor-pointer  ${isSelected(item?.id)
                                                            ? "bg-blue-100 pr-1 flex items-center border border-1B74E4"
                                                            : "border border-gray-400 bg-white hover:bg-gray-100 pr-3"
                                                            }`}
                                                        key={item.id}
                                                        onClick={() => { businessProductSelectHandler(item); }}
                                                    >
                                                        {item.name}
                                                        {isSelected(item?.id) && (
                                                            <div onClick={(e) => { e.stopPropagation(); removeProduct(item?.id); }}>
                                                                <i
                                                                    className="fas fa-times text-[#1B74E4] w-5 h-5 ml-2 cursor-pointer"
                                                                ></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="bg-gray-100 p-4">{"Search for anything else!"}</div>
                                    }
                                    {/* components import in company change height className add this className "h-[635px]" * /}


                            </div> */}
                            </div>

                            <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                                <button type="button" className='btn btn-primary btn-sm'
                                    //disabled={nextBtnDisable}
                                    disabled={selectedProduct?.length === 0}
                                    onClick={() => { nextBtn() }}
                                >
                                    Selected
                                </button>
                                {/* <button type="submit" className="btn btn-primary">
                                        Create
                                    </button> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProductModel