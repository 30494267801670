import React, { useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Controller, useForm } from 'react-hook-form';

import Select from 'react-select';
import { FilterState } from '../../../../../_metronic/helpers/crud-helper/models';
import CallFor from '../../../../routing/CallFor';
import GetSearchPinCodeHandler, { PinCodeTypes } from './GetSearchPinCodeHandler';
import { useParams } from 'react-router-dom';
import CategoryModal from '../modal/CategoryModal';
import ProductModel from '../modal/ProductModel';
import ApiErrorMessage from '../../../../hooks/apiErrorMessage';
import { useNavigate } from 'react-router-dom';
import { AUTH, GET, POST } from '../../../../../constants/global';
import { Field } from 'formik';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../../_metronic/helpers';

const CompanyAdd: React.FC = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const changeBusinessModalRef = useRef<HTMLDivElement>(null);

    type UserSubmitForm = {
        verifiedBy: number;
        identityNumber: string;
        name: string;
        type: number;
        natureOfBusiness: number;
        pincodeId: string;
        pinCode: string;
        sellProduct: any;
        buyProduct: any
    }
    const { handleSubmit, control } = useForm<UserSubmitForm>({})

    const initValues = {
        verifiedBy: 0, //Identification Type
        identityNumber: "",//Identification Number
        name: "",//Business Name
        type: 0,//Firm Type
        natureOfBusiness: 0,//Business Type
        pincodeId: "",
        pinCode: "",
        city: "",
        state: "",
        sellProduct: [] as string[],
        buyProduct: [] as string[],

        verifiedBy_error: "",
        identityNumber_error: "",
        name_error: "",
        type_error: "",
        natureOfBusiness_error: "",
        pinCode_error: "",
        city_error: "",
        state_error: "",
        sellProduct_error: "",
        buyProduct_error: "",

        // firmType: "",
        // firmType_error: "",
        // businessType: "",
        // businessType_error: "",
    }

    const [values, setValues] = useState(initValues)

    const [businessTypes, setBusinessTypes] = useState<any[]>([]);
    const [options, setOptions] = useState<PinCodeTypes[]>([]);
    const [selectedPinCode, setSelectedPinCode] = useState({ pin: "", city: "", state: "", stateId: "", area: "", pincodeId: "" })
    const [pinCodeValue, setPinCodeValue] = useState<any>("")
    const { searchPinCodeHandler } = GetSearchPinCodeHandler();
    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [modalCategory, setModalCategory] = useState(false);
    const [product, setProduct] = useState<any[]>([]);
    const [productId, setProductId] = useState([]);
    const [modalProduct, setModalProduct] = useState(false);
    const [productBuy, setProductBuy] = useState<any[]>([]);
    const [productBuyId, setProductBuyId] = useState([]);
    const [modalProductBuy, setModalProductBuy] = useState(false);
    const [showBasicDetail, setShowBasicDetail] = useState(false);
    const [loadingVerify, setLoadingVerify] = useState<Boolean>(false);
    const [loadingCreate, setLoadingCreate] = useState<Boolean>(false);


    useEffect(() => {
        businessTypeList()
        console.log("userId:", id)
    }, [])

    useEffect(() => {
        // const productIds = (product as { productId: string }[]).map(({ productId }) => productId).join(", ");
        // //console.log("productIds:", productIds)
        // setValues({ ...values, sellProduct: productIds, sellProduct_error: '' });
        const productIdsArray = (product as { productId: string }[]).map(({ productId }) => productId);
        setValues({ ...values, sellProduct: productIdsArray, sellProduct_error: '' });
    }, [product])
    useEffect(() => {
        const productBuyIds = (productBuy as { productId: string }[]).map(({ productId }) => productId);
        //console.log("productIds:", productIds)
        setValues({ ...values, buyProduct: productBuyIds, buyProduct_error: '' });
    }, [productBuy])

    const businessTypeList = async () => {
        const response = await CallFor('api/admin/v1/businesses/nob', GET, null, AUTH)
        if (response.status === 200) {
            const res = await response.json();
            const states = await res.data.map(
                (d: { type: any; name: any }) => ({
                    value: d.type,
                    label: d.name
                })
            );
            setBusinessTypes(states);
        }
    }

    const IdentificationNumberVerify = async () => {
        const jsonData = {
            identity: values.verifiedBy,
            identityNumber: values.identityNumber,
        }
        const data = JSON.stringify(jsonData);
        setLoadingVerify(true);
        const response = await CallFor(`api/admin/v1/identity/verify/${id}`, POST, data, AUTH)
        if (response.status === 200) {
            const res = await response.json();
            setValues({ ...values, name: res.data.companyName, name_error: '' });
            setShowBasicDetail(true);
            setLoadingVerify(false);
        }
        else if (response.status === 400) {

            const dataRes = await response.json();
            console.log(dataRes);
            if (dataRes.error?.subcode) {

                const subCode: number = parseInt(dataRes.error.subcode);
                const error1 = await ApiErrorMessage(subCode, dataRes.error.code);
                console.log(error1);
                setValues({ ...values, identityNumber: values.identityNumber, identityNumber_error: error1 });
                toast.error(error1)

            }

            setLoadingVerify(false);


        }
    }

    const searchPinCode = (inputValue: React.ChangeEvent<HTMLInputElement>) => {
        searchPinCodeHandler(
            inputValue,
            setPinCodeValue,
            //values.pinCode_error,
            setOptions,
            //"Enter more than 2 and less than 200 characters",
            "PinCode Not Found",
            setSelectedPinCode
        );
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {

        const validationField = [event.target.name] + '_error'
        setValues({ ...values, [event.target.name]: event.target.value, [validationField]: '' });
    }

    const handleChangeDropdown = (event: any, name: any) => {

        const validationField = [name] + '_error'
        if (event !== null && event.value !== undefined) {
            setValues({ ...values, [name]: event.value, [validationField]: '' });
        }
        else {
            setValues({ ...values, [name]: '' });
        }
        console.log(values);
    }



    const selectPinCodeHandler = (
        pin: string,
        area: string,
        city: string,
        state: string,
        stateId: string,
        pincodeId: string
    ) => {
        // Define an object to accumulate the state updates
        let updatedValues = { ...values };

        if (pin !== "") {
            setPinCodeValue(`${pin}`);
            updatedValues = { ...updatedValues, pinCode: pin, pinCode_error: '' };
        }

        if (pincodeId !== "") {
            updatedValues = { ...updatedValues, pincodeId };
        }

        if (city !== "") {
            updatedValues = { ...updatedValues, city, city_error: '' };
        }

        if (state !== "") {
            updatedValues = { ...updatedValues, state, state_error: '' };
        }

        // Update all values at once
        setValues(updatedValues);

        setSelectedPinCode({
            pin: pin,
            area: area,
            city: city,
            state: state,
            stateId: stateId,
            pincodeId: pincodeId
        });
        setOptions([]);
        console.log(values);
    };
    const verifiedByType = [
        { value: 1, label: 'GSTIN' },
        { value: 2, label: 'PAN NUMBER' },
        // { value: 'MOBILE NUMBER', label: 'MOBILE NUMBER' }
        { value: 3, label: 'UDYAM ADHAR' },
        { value: 4, label: 'UDYOG ADHAR' },

    ]
    const IndustryType = [
        {
            entityType: 'PROPRIETORSHIP',
            value: 1,
            label: 'Proprietorship',
        },
        {
            entityType: 'PARTNERSHIP_LLP',
            value: 2,
            label: 'Partnership/LLP',
        },
        {
            entityType: 'PUBLIC_LIMITED_COMPANY',
            value: 3,
            label: 'Public Limited Company',
        },
        {
            entityType: 'PVT_LIMITED_COMPANY',
            value: 4,
            label: 'Pvt. Limited Company',
        },
        {
            entityType: 'TRUST',
            value: 5,
            label: 'Trust',
        },
        {
            entityType: 'SOCIETIES',
            value: 6,
            label: 'Societies',
        },
        {
            entityType: 'ASSOCIATIONS_CLUB',
            value: 7,
            label: 'Associations / Club',
        },
        {
            entityType: 'BANK_FINANCIAL_INSTITUTATION',
            value: 8,
            label: 'Bank / Financial Institution',
        },
        {
            entityType: 'EDUCATION_INSTUATION',
            value: 9,
            label: 'Education Institution',
        },
        {
            entityType: 'GOVERNMENT_PUBLIC_SECTOR_Undertaking',
            value: 10,
            label: 'Government / Public Sector Undertaking',
        },
        {
            entityType: 'OTHERS',
            value: 11,
            label: 'Others',
        }

    ]
    const identityTypeHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setValues({ ...values, verifiedBy: event.value, type_error: '' });
        }
        else {
            setValues({ ...values, verifiedBy: 0, type_error: '' });
        }
    }
    const dataChangeHandler = (e: any) => {
        setValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value, [`${e.target.name}_error`]: "" }))
        setValues((prev: any) => ({ ...prev, [`${e.target.name}_error`]: '' }))
    }

    const changeHandler = (e: any, isErrorHandler = true) => {

        if (isErrorHandler === true) {
            setValues({ ...values, [e.target.name]: e.target.value, [`${e.target.name}_error`]: "" })
        }
        else {
            setValues({ ...values, [e.target.name]: e.target.value })
        }

    }


    const clickCategoryHandler = () => {
        if (modalCategory === true) {
            setModalCategory(false)
        }
        setModalCategory(true)
    }
    const clickProductHandler = () => {
        if (modalProduct === true) {
            setModalProduct(false)
        }
        setModalProduct(true)
    }
    const clickProductBuyHandler = () => {
        if (modalProductBuy === true) {
            setModalProductBuy(false)
        }
        setModalProductBuy(true)
    }

    const VerifySchema = () => {
        if (values.identityNumber.trim() === '' && values.verifiedBy === 0) {
            setValues((prev: any) => ({ ...prev, identityNumber_error: 'Please Select a identity number', verifiedBy_error: 'Please Select a identity type' }))
            return false
        }
        else {
            if (values.identityNumber !== '' && values.verifiedBy === 0) {
                setValues((prev: any) => ({ ...prev, verifiedBy_error: 'Please Select a identity type' }))
                return false
            }
            if (values.identityNumber.trim() === '' && values.verifiedBy !== 0) {
                setValues((prev: any) => ({ ...prev, identityNumber_error: 'Please Select a identity number' }))
                return false
            }
            if (values.identityNumber !== '' && values.verifiedBy === 1) {

                if (!values.identityNumber.trim().match(/[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1}/)) {
                    setValues((prev: any) => ({ ...prev, identityNumber_error: 'Enter valid GST number' }))
                    return false
                }
                else if (values.identityNumber.trim().length < 15) {
                    setValues((prev: any) => ({ ...prev, identityNumber_error: 'GST number must be 15 digit' }))
                    return false
                }
                else if (values.identityNumber.trim().length > 15) {
                    setValues((prev: any) => ({ ...prev, identityNumber_error: 'GST number maximum length must be 15 digit' }))
                    return false
                }
            }
            if (values.identityNumber !== '' && values.verifiedBy === 2) {
                if (!values.identityNumber.trim().match(/[a-zA-Z]{3}[pcftghlabjPCFTGHLABJ]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}/)) {
                    setValues((prev: any) => ({ ...prev, identityNumber_error: 'Enter valid PAN number' }))
                    return false
                }
                else if (values.identityNumber.trim().length < 10) {
                    setValues((prev: any) => ({ ...prev, identityNumber_error: 'Enter valid PAN number' }))
                    return false
                }
                else if (values.identityNumber.trim().length > 10) {
                    setValues((prev: any) => ({ ...prev, identityNumber_error: 'Enter valid PAN number' }))
                    return false
                }
            }

            if (values.identityNumber !== '' && values.verifiedBy === 3) {
                const udyamAdharPattern = /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/; // Example pattern
                if (!values.identityNumber.trim().match(udyamAdharPattern)) {
                    setValues((prev: any) => ({ ...prev, identityNumber_error: 'Enter valid UDYAM ADHAR' }))
                    return false
                }
            }
        }

        return true;
    }

    const VerifyBusiness = () => {
        if (VerifySchema()) {

            IdentificationNumberVerify();
        }
    }
    const ChangeBusiness = () => {
        const divElement = document.createElement("div")
        divElement.classList.add("modal-backdrop")
        divElement.classList.add("fade")
        divElement.classList.add("show")
        const body = document.getElementById("kt_app_body")
        body?.appendChild(divElement)
        changeBusinessModalRef.current!.style.display = "block"
        changeBusinessModalRef.current!.style.paddingLeft = "0px"
        changeBusinessModalRef.current!.classList.add("show")
        changeBusinessModalRef.current!.setAttribute("aria-modal", "true")
        changeBusinessModalRef.current!.setAttribute("role", "dialog")
        changeBusinessModalRef.current!.removeAttribute("aria-hidden")
    }

    const ErrorMsg =
    {
        verifiedBy: "Select Identification Type",
        identityNumber: "Identity Number is Required",
        name: "Business Name is Required",
        type: "Select Firm type",
        natureOfBusiness: "Select the business type",
        pincode: "Enter pincode",
        sellProduct: "Invalid sell product list",
    }
    const validationSchema = () => {

        if (values.verifiedBy === 0 && values.identityNumber.trim() === '' && values.name.trim() === '' && values.type === 0 && values.natureOfBusiness === 0 && values.pinCode.trim() === '' && values.sellProduct.length === 0) {
            setValues((prev: any) => ({
                ...prev, verifiedBy_error: ErrorMsg.verifiedBy, identityNumber_error: ErrorMsg.identityNumber, name_error: ErrorMsg.name, type_error: ErrorMsg.type,
                natureOfBusiness_error: ErrorMsg.natureOfBusiness,
                pinCode_error: ErrorMsg.pincode, sellProduct_error: ErrorMsg.sellProduct
            }))
            return false
        }
        else {
            if (values.verifiedBy === 0) {
                setValues((prev: any) => ({ ...prev, verifiedBy_error: ErrorMsg.verifiedBy }))
                return false
            }
            if (values.identityNumber.trim() === '') {
                setValues((prev: any) => ({ ...prev, identityNumber_error: ErrorMsg.identityNumber }))
                return false
            }
            if (values.name.trim() === '') {
                setValues((prev: any) => ({ ...prev, name_error: ErrorMsg.name }))
                return false
            }
            if (values.type === 0) {
                setValues((prev: any) => ({ ...prev, type_error: ErrorMsg.type }))
                return false
            }
            if (values.natureOfBusiness === 0) {
                setValues((prev: any) => ({ ...prev, natureOfBusiness_error: ErrorMsg.natureOfBusiness }))
                return false
            }
            if (values.pinCode.trim() === '') {
                setValues((prev: any) => ({ ...prev, pinCode_error: ErrorMsg.pincode }))
                return false
            }
            if (values.sellProduct.length === 0) {
                setValues((prev: any) => ({ ...prev, sellProduct_error: ErrorMsg.sellProduct }))
                return false
            }
        }
        return true
    }
    const submitHandler = async () => {

        if (validationSchema()) {
            const jsonData = {
                userId: id,
                verifiedBy: values.verifiedBy,
                identityNumber: values.identityNumber.trim(),
                name: values.name,
                type: values.type,
                natureOfBusiness: values.natureOfBusiness,
                pincode: values.pinCode,
                pincodeId: values.pincodeId,
                sellProduct: values.sellProduct,
                buyProduct: values.buyProduct,
                companyProfileImg: null,
                companyCoverImg: null,

            }
            setLoadingCreate(true)
            console.log("submitHandler", jsonData);
            const data = JSON.stringify(jsonData);
            const response = await CallFor(
                `api/admin/v1/businesses`,
                POST,
                data,
                AUTH
            )
            try {

                if (response?.status === 201) {
                    toast.success("Create Company Successfully");
                    setValues((prev: any) => ({
                        ...prev,
                        verifiedBy: "", //Identification Type
                        identityNumber: "",//Identification Number
                        name: "",//Business Name
                        type: 0,//Firm Type
                        natureOfBusiness: "",//Business Type
                        pincodeId: "",
                        pinCode: "",
                        city: "",
                        state: "",
                        sellProduct: "",
                        buyProduct: "",

                        verifiedBy_error: "",
                        identityNumber_error: "",
                        name_error: "",
                        type_error: "",
                        natureOfBusiness_error: "",
                        pinCode_error: "",
                        city_error: "",
                        state_error: "",
                        sellProduct_error: "",
                        buyProduct_error: "",
                    }))
                    navigate('/mis/user')

                }
                else if (response.status === 400) {
                    const dataRes = await response.json();
                    console.log(dataRes);
                    if (dataRes.error?.subcode) {

                        const subCode: number = parseInt(dataRes.error.subcode);
                        const error1 = await ApiErrorMessage(subCode, dataRes.error.code);
                        console.log(error1);
                        toast.success(error1);

                    }
                    else {

                        dataRes?.error?.errors?.forEach(async (details: { field: any; message: string }) => {
                            const subCode = parseInt(details?.message);

                            let errorMsg = await ApiErrorMessage(subCode, ""); // Await the promise here

                            // console.log("data Error :" + subCode, details?.field, details?.message)
                            switch (subCode) {
                                case 1757:
                                    setValues((prev: any) => ({ ...prev, type_error: errorMsg }));
                                    break;
                                case 1765:
                                    setValues((prev: any) => ({ ...prev, sellProduct_error: errorMsg }));
                                    break;
                                case 1758:
                                    setValues((prev: any) => ({ ...prev, verifiedBy_error: errorMsg }));
                                    break;

                                case 1759:
                                    setValues((prev: any) => ({ ...prev, identityNumber_error: errorMsg }));
                                    break;
                                case 1756:
                                    setValues((prev: any) => ({ ...prev, natureOfBusiness_error: errorMsg }));
                                    break;
                                case 1754:
                                    setValues((prev: any) => ({ ...prev, pinCode_error: errorMsg }));
                                    break;
                                case 1755: //pincodeId
                                    console.log("pincodeId", errorMsg)
                                    //    setValues((prev: any) => ({ ...prev, mobileNo_error: errorMsg }));
                                    break;
                                default:
                                    console.log("Unhandled subCode: ", subCode);
                                    break;
                            }
                        });



                    }
                    setLoadingCreate(false)
                }
                else {
                    const dataRes = await response.json();
                    console.log(dataRes);
                    setLoadingCreate(false)
                }
            } catch (err) {
                console.log(err);
                setLoadingCreate(false)
            }

        }
    }

    const closeChangeBusinessModal = () => {
        changeBusinessModalRef.current!.style!.display = "none"
        changeBusinessModalRef.current!.classList.remove("show")
        changeBusinessModalRef.current!.removeAttribute("aria-modal")
        changeBusinessModalRef.current!.removeAttribute("role")
        changeBusinessModalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop')
        divElement[0].remove()
    }
    const changeBusinessDetail = async () => {

        closeChangeBusinessModal();
        setShowBasicDetail(false);
        const dataVerifiedBy = values.verifiedBy;
        const dataIdentityNumber = values.identityNumber;
        selectPinCodeHandler("", "", "", "", "", "")
        setCategory([])
        setCategoryId([])
        setProduct([])
        setProductId([])
        setProductBuy([])
        setProductBuyId([])
        setOptions([]);
        setValues((prev: any) => ({
            ...prev,
            verifiedBy: dataVerifiedBy, //Identification Type
            identityNumber: dataIdentityNumber,//Identification Number
            name: "",//Business Name
            type: 0,//Firm Type
            natureOfBusiness: "",//Business Type
            pincodeId: "",
            pinCode: "",
            city: "",
            state: "",
            sellProduct: "",
            buyProduct: "",

            verifiedBy_error: "",
            identityNumber_error: "",
            name_error: "",
            type_error: "",
            natureOfBusiness_error: "",
            pinCode_error: "",
            city_error: "",
            state_error: "",
            sellProduct_error: "",
            buyProduct_error: "",
        }))
        // setValues(initValues);

        // setValues({ ...values, verifiedBy: dataVerifiedBy, identityNumber: dataIdentityNumber })



    }
    return (
        <div>
            <PageTitle >Create Company</PageTitle>
            {/* <Chip label="Barbados" onDelete={() => {}} /> */}
            {/* <div id="filterChipsContainer" className="chip-group" style={{ display: "inline-flex" }}>
                <div className="chip" >
                    <span>Hello, World</span>
                    <button type="button" title="Remove chip" aria-label="Remove chip" >
                        <i className='fas fa-times'></i>
                    </button>
                </div>
            </div> */}

            <div className='card mb-5 p-5'>
                <div className='row'><h3>Business Detail</h3></div>
                <div className='row'>
                    <div className="col-lg-3">
                        <label className='form-label'>Identification Type *</label>
                        <Controller
                            name='verifiedBy'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isClearable
                                    isSearchable={true}
                                    placeholder='Select Identification Type'
                                    className={values.verifiedBy_error ? 'error-border' : ''}
                                    value={verifiedByType.filter(
                                        (obj) => obj.value === values.verifiedBy)}
                                    onChange={identityTypeHandler}
                                    id="type"
                                    options={verifiedByType}
                                    isDisabled={showBasicDetail ? true : false}
                                />
                            )} />
                        <p id='error_1' className='errormsg' >{values?.verifiedBy_error}</p>
                    </div>

                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Identification Number *</label>
                        <input
                            value={values!.identityNumber.toUpperCase()}
                            onChange={dataChangeHandler}
                            name="identityNumber"
                            type="text"
                            className={`form-control ${values.identityNumber_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Enter Identification Number"
                            disabled={showBasicDetail ? true : false}
                        />
                        <p className='errormsg' >{values.identityNumber_error}</p>
                    </div>
                    <div className="mb-3 mt-5 col-lg-3">
                        <>
                            {showBasicDetail === false &&
                                <button type="button" className="btn btn-primary mt-3"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        VerifyBusiness()
                                    }}
                                    disabled={loadingVerify ? true : false}
                                >
                                    {!loadingVerify && 'Verify'}
                                    {loadingVerify && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            }
                            {showBasicDetail === true &&
                                <button type="button" className="btn btn-primary mt-3" onClick={(e) => {
                                    e.stopPropagation();
                                    ChangeBusiness()
                                }}>
                                    Change Business
                                </button>
                            }
                        </>
                    </div>
                </div>
            </div>
            {showBasicDetail === true &&
                <>
                    <form onSubmit={handleSubmit(submitHandler)} >
                        <div className='card mb-5 p-5'>
                            <div className='row'><h3>Basic Details</h3></div>
                            <div className='row'>
                                <div className="mb-3 col-xxl-3 col-lg-4">
                                    <label className="form-label">Business Name*</label>
                                    <input
                                        type="text"
                                        name='name'
                                        // className="form-control"
                                        className={`form-control ${values?.name_error === "" ? '' : 'is-invalid'}`}
                                        placeholder="Enter Business Name"
                                        value={values.name}
                                        onChange={(e) => handleChange(e)}
                                        disabled
                                    />
                                    <p id='error_1' className='errormsg' >{values?.name_error}</p>
                                </div>

                                <div className="mb-3 col-xxl-3 col-lg-4">
                                    <label className="form-label">Firm Type*</label>


                                    <Controller
                                        name='type'
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                name='type'
                                                isClearable
                                                isSearchable={true}
                                                placeholder='Select Firm Type'
                                                id='type'
                                                onChange={(e) => handleChangeDropdown(e, 'type')}
                                                value={IndustryType.filter((obj: any) => obj.value === values.type)}
                                                options={IndustryType}
                                                // options={
                                                //     IndustryType().map((e: any) => ({
                                                //         value: e.value,
                                                //         label: e.label
                                                //     }))
                                                // }
                                                className={values?.type_error ? 'error-border' : ''}
                                            />
                                        )}
                                    />
                                    <p id='error_1' className='errormsg' >{values?.type_error}</p>
                                </div>
                                <div className="mb-3 col-xxl-3 col-lg-4">
                                    <label className="form-label">Business Type*</label>
                                    <Controller
                                        name='natureOfBusiness'
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                name='natureOfBusiness'
                                                isClearable
                                                isSearchable={true}
                                                placeholder='Select Business Type'
                                                //onChange={() => handleChange}
                                                id='natureOfBusiness'
                                                onChange={(e) => handleChangeDropdown(e, 'natureOfBusiness')}
                                                value={businessTypes.filter((obj: any) => obj.value === values.natureOfBusiness)}
                                                //value={values.businessType}
                                                options={businessTypes}
                                                className={values?.natureOfBusiness_error ? 'error-border' : ''}
                                            />
                                        )}
                                    />
                                    <p id='error_1' className='errormsg' >{values?.natureOfBusiness_error}</p>
                                </div>
                                <div className="mb-3 col-xxl-3 col-lg-4 relative">
                                    <label className="form-label">PinCode*</label>
                                    <Controller
                                        name="pinCode"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type="text"
                                                name='pinCode'
                                                onChange={(e) => handleChange(e)}

                                                onInput={searchPinCode}
                                                maxLength={6}
                                                className={`form-control ${values?.pinCode_error === "" ? '' : 'is-invalid'}`}
                                                placeholder="Enter PinCode"
                                                value={values.pinCode}
                                            />
                                        )}
                                    />
                                    <p id='error_1' className='errormsg' >{values?.pinCode_error}</p>
                                    {options?.length > 0 && values.pinCode?.length === 6 && (
                                        <div className="border rounded-md z-10 bg-white-custom absolute w-full max-h-48 mt-1 overflow-auto">
                                            {options.map((item: PinCodeTypes, i: number) => (
                                                <div
                                                    className="py-2 px-2  cursor-pointer bg-gray-100-custom"
                                                    onClick={() =>
                                                        selectPinCodeHandler(
                                                            item?.pincode,
                                                            item?.area,
                                                            item?.city,
                                                            item?.state,
                                                            item.stateId,
                                                            item?.pincodeId
                                                        )
                                                    }
                                                    key={i}
                                                >
                                                    {item?.pincode}, {item?.area}, {item?.city}, {item?.state}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {/* <div className="mb-3 col-xxl-3 col-lg-4">
                            <label className="form-label">PinCode*</label>

                            <input
                                type="text"
                                name='pinCode'
                                // className="form-control"
                                className={`form-control ${values?.pinCode_error === "" ? '' : 'is-invalid'}`}
                                placeholder="Enter PinCode"
                                value={values.pinCode}
                                onChange={(e) => handleChange(e)}
                            />
                            <p id='error_pinCode' className='errormsg' >{values?.pinCode_error}</p>
                        </div> */}
                                <div className="mb-3 col-xxl-3 col-lg-4">
                                    <label className="form-label">City*</label>

                                    <input
                                        type="text"
                                        name='city'
                                        // className="form-control"
                                        className={`form-control ${values?.city_error === "" ? '' : 'is-invalid'}`}
                                        placeholder="Enter city"
                                        value={values.city}
                                        onChange={(e) => handleChange(e)}
                                        disabled
                                    />
                                    <p id='error_city' className='errormsg' >{values?.city_error}</p>
                                </div>
                                <div className="mb-3 col-xxl-3 col-lg-4">
                                    <label className="form-label">State*</label>

                                    <input
                                        type="text"
                                        name='state'
                                        // className="form-control"
                                        className={`form-control ${values?.state_error === "" ? '' : 'is-invalid'}`}
                                        placeholder="Enter State"
                                        value={values.state}
                                        onChange={(e) => handleChange(e)}
                                        disabled
                                    />
                                    <p id='error_state' className='errormsg' >{values?.state_error}</p>
                                </div>

                                <div className="mb-3 col-xxl-12 col-lg-12">
                                    <label className="form-label">Business Category *</label>
                                    {/* <div className="border border-gray-300 rounded-lg py-3 px-2 text-gray-800" > */}
                                    <div className={`border border-dashed border-gray-300 rounded-lg min-h-80px w-full flex flex-wrap gap-2 p-2 cursor-pointer`} onClick={clickCategoryHandler}>
                                        {category && category.length === 0 ?
                                            <>{"Business Category"}</>
                                            :
                                            <>
                                                {category.map((category: any) => (
                                                    <div key={category.id} className="bg-blue-100 py-2 pl-3 pr-3 h-8 border border-1B74E4 rounded-full text-sm text-gray-800 font-medium cursor-pointer w-fit flex items-center mb-1 mr-1" >
                                                        {/* onClick={(e) => { e.stopPropagation(); categorySelectHandler(category) }}> */}
                                                        {category.name}
                                                        {/* <i className="fas fa-times w-5 h-5 ml-2 cursor-pointer mt-5px"></i> */}
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>

                                    {/* </div> */}
                                </div>

                                <div className="mb-3 col-xxl-12 col-lg-12">
                                    <label className="form-label"> Sell Product*</label>
                                    {/* <div className="border border-gray-300 rounded-lg py-3 px-2 text-gray-800" > */}
                                    <div className={`border border-dashed border-gray-300 rounded-lg min-h-80px w-full flex flex-wrap gap-2 p-2 cursor-pointer`} onClick={clickProductHandler}>
                                        {product && product.length === 0 ?
                                            <>{"Sell Product"}</>
                                            :
                                            <>
                                                {product && product.map((prod: any) => (
                                                    <div key={prod.productId} className="bg-blue-100 py-2 pl-3 pr-3 h-8 border border-1B74E4 rounded-full text-sm text-gray-800 font-medium cursor-pointer w-fit flex items-center mb-1 mr-1">
                                                        {/* onClick={(e) => { e.stopPropagation(); productSelectHandler(prod) }}> */}
                                                        {prod.productName}
                                                        {/* <i className="fas fa-times w-5 h-5 ml-2 cursor-pointer mt-5px"></i> */}

                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>
                                    {/* </div> */}
                                    <p id='error_sellProduct' className='errormsg' >{values?.sellProduct_error}</p>
                                </div>
                                <div className="mb-3 col-xxl-12 col-lg-12">
                                    <label className="form-label">Buy Product </label>
                                    {/* <div className="border border-gray-300 rounded-lg py-3 px-2 text-gray-800" > */}
                                    <div className={`border border-dashed border-gray-300 rounded-lg min-h-80px w-full flex flex-wrap gap-2 p-2 cursor-pointer`} onClick={clickProductBuyHandler}>
                                        {productBuy && productBuy.length === 0 ?
                                            <>{"Buy Product"}</>
                                            :
                                            <>
                                                {productBuy && productBuy.map((prod: any) => (
                                                    <div key={prod.productId} className="bg-blue-100 py-2 pl-3 pr-3 h-8 border border-1B74E4 rounded-full text-sm text-gray-800 font-medium cursor-pointer w-fit flex items-center mb-1 mr-1">
                                                        {/* onClick={(e) => { e.stopPropagation(); productSelectHandler(prod) }}> */}
                                                        {prod.productName}
                                                        {/* <i className="fas fa-times w-5 h-5 ml-2 cursor-pointer mt-5px"></i> */}

                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>
                                    {/* </div> */}
                                    <p id='error_buyProduct' className='errormsg' >{values?.buyProduct_error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                            <button type="submit" className="btn btn-primary"
                                disabled={loadingCreate ? true : false}
                            >
                                {!loadingCreate && 'Create'}
                                {loadingCreate && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}

                            </button>
                        </div>
                    </form >


                    <CategoryModal
                        modalCategory={modalCategory}
                        setModalCategory={setModalCategory}
                        categoryId={categoryId}
                        setCategoryId={setCategoryId}
                        setCategory={setCategory}
                        setProductId={setProductId}
                        setProduct={setProduct}
                        nextBtnCall={clickProductHandler} />

                    <ProductModel
                        category={category}
                        modalProduct={modalProduct}
                        setModalProduct={setModalProduct}
                        productId={productId}
                        setProductId={setProductId}
                        setProduct={setProduct} />

                    <ProductModel
                        isProductBuy={true}
                        category={category}
                        modalProduct={modalProductBuy}
                        setModalProduct={setModalProductBuy}
                        productId={productBuyId}
                        setProductId={setProductBuyId}
                        setProduct={setProductBuy} />

                </>
            }

            <div className="modal fade" ref={changeBusinessModalRef} tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog modelLower">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title">Change Business</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                // data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={closeChangeBusinessModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className='inactiveName'>Are you sure you want to change business detail ? </p>
                            <p><b>Notes:</b> If you select 'Yes,' you will need to re-enter the basic details.  </p>
                        </div>
                        <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
                            <button className="btn btn-primary btn-sm" onClick={changeBusinessDetail}>Yes</button>
                            <button className="btn btn-secondary btn-sm" onClick={closeChangeBusinessModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )


}


export default CompanyAdd