import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Designation from '../modules/widgets/Management/Designation'
import Employee from '../modules/widgets/Management/Employee'
import KnowledgePartner from '../modules/widgets/Management/KnowledgePartner'
import InquiryNow from '../modules/widgets/KnowledgePartner/InquiryNow'
import AddCustomer from '../modules/widgets/KnowledgePartner/AddCustomer'
import AddKnowledgePartner from '../modules/widgets/KnowledgePartner/AddKnowledgePartner'
import UserSearch from '../modules/widgets/KnowledgePartner/UserSearch'
import MisReport from '../modules/widgets/KnowledgePartner/MisReport'
import EditKnowledgePartner from '../modules/widgets/KnowledgePartner/EditKnowledgePartner'
import Notification from '../modules/widgets/Notification/Notification'
import PushNotification from '../modules/widgets/Notification/PushNotifcation'
import BannerNotification from '../modules/widgets/Notification/BannerNotification'
import CreateBanner from '../modules/widgets/Notification/CreateBanner';
import Creative from '../modules/widgets/Notification/Creative'
import CreativeNotification from '../modules/widgets/Notification/CreativeNotification'
import Feature from '../modules/widgets/PlanPanel/Feature'
import Plan from '../modules/widgets/PlanPanel/Plan'
import CreatePromoCode from '../modules/widgets/PromoCode/CreatePromoCode'
import PromoCodeListing from '../modules/widgets/PromoCode/PromoCodeListing'
import PaymentReport from '../modules/widgets/PaymentReport/PaymentReport'
import PlanUserActivity from '../modules/widgets/PaymentReport/PlanUserActivity'
import AddUser from '../modules/widgets/Registration/User/AddUser'
import CompanyAdd from '../modules/widgets/Registration/Company/CompanyAdd'
import UploadCsv from '../modules/widgets/campaign/UploadCsv'
import CampaignSearch from '../modules/widgets/campaign/CampaignSearch'


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='designation/*'
          element={
            <SuspensedView>
              <Designation />
            </SuspensedView>
          }
        />
        <Route
          path='employee/*'
          element={
            <SuspensedView>
              <Employee />
            </SuspensedView>
          }
        />
        <Route
          path='knowledgepartner/*'
          element={
            <SuspensedView>
              <KnowledgePartner />
            </SuspensedView>
          }
        />
        <Route
          path='inquiries/*'
          element={
            <SuspensedView>
              <InquiryNow />
            </SuspensedView>
          }
        />
        <Route
          path='mis/user/*'
          element={
            <SuspensedView>
              <UserSearch />
            </SuspensedView>
          }
        />
        <Route
          path='mis/user/add'
          element={
            <SuspensedView>
              <AddUser />
            </SuspensedView>
          }
        />
        <Route
          path='mis/user/:id/bussiness/add'
          element={
            <SuspensedView>
              <CompanyAdd />
            </SuspensedView>
          }
        />
        <Route
          path='mis/report/*'
          element={
            <SuspensedView>
              <MisReport />
            </SuspensedView>
          }
        />
        {/* <Route
          path='mis/*'
          element={
            <SuspensedView>
              <MisReport />
            </SuspensedView>
          }
        /> */}
        <Route
          path='addcustomer/*'
          element={
            <SuspensedView>
              <AddCustomer />
            </SuspensedView>
          }
        />
        <Route
          path='addknowledgepartner/*'
          element={
            <SuspensedView>
              <AddKnowledgePartner />
            </SuspensedView>
          }
        />
        <Route
          path='notification/*'
          element={
            <SuspensedView>
              <Notification />
            </SuspensedView>
          }
        />
        <Route
          path='feature/*'
          element={
            <SuspensedView>
              <Feature />
            </SuspensedView>
          }
        />
        <Route
          path='plan/*'
          element={
            <SuspensedView>
              <Plan />
            </SuspensedView>
          }
        />
        <Route
          path='pushnotification/*'
          element={
            <SuspensedView>
              <PushNotification />
            </SuspensedView>
          }
        />
        <Route
          path='createBanner/*'
          element={
            <SuspensedView>
              <CreateBanner />
            </SuspensedView>
          }
        />
        <Route
          path='/bannerNotification'
          element={
            <SuspensedView>
              <BannerNotification />
            </SuspensedView>
          }
        />
        <Route
          path='/creative'
          element={
            <SuspensedView>
              <Creative />
            </SuspensedView>
          }
        />
        <Route
          path='/CreativeNotification'
          element={
            <SuspensedView>
              <CreativeNotification />
            </SuspensedView>
          }
        />
        <Route
          path='editKnowledgePartner/:id'
          element={
            <SuspensedView>
              <EditKnowledgePartner />
            </SuspensedView>
          }
        />
        <Route
          path='wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='/createpromoCode'
          element={
            <SuspensedView>
              <CreatePromoCode />
            </SuspensedView>
          }
        />
        <Route
          path='/promoCodelisting'
          element={
            <SuspensedView>
              <PromoCodeListing />
            </SuspensedView>
          }
        />
        <Route
          path='/paymentreport'
          element={
            <SuspensedView>
              <PaymentReport />
            </SuspensedView>
          }
        />
        <Route
          path='/planuseractivity'
          element={
            <SuspensedView>
              <PlanUserActivity />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/search'
          element={
            <SuspensedView>
              <CampaignSearch />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/UploadCsv'
          element={
            <SuspensedView>
              <UploadCsv />
            </SuspensedView>
          }
        />
        {/* <Route
          path='registration/users/*'
          element={
            <SuspensedView>
              <UserList />
            </SuspensedView>
          }
        />
        <Route
          path='registration/users/add'
          element={
            <SuspensedView>
              <AddUser />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='registration/company/*'
          element={
            <SuspensedView>
              <CompanySearch />
            </SuspensedView>
          }
        />
        <Route
          path='registration/company/add'
          element={
            <SuspensedView>
              <CompanyAdd />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
