// methods
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'

// call for type
export const REG_AUTH = 'registrationWithAuth'
export const REG_WITHOUT_CONTENT = 'registrationWithoutContentType'
export const AUTH = 'Auth'
export const AUTH_WITH_FORM_DATA = 'authWithoutContentType'
export const AUTH_WITH_CONTENT_TYPE = 'AuthwithContentType'
export const TEST = 'test'
export const WITH_OUT_AUTH = 'withoutAuth'
export const WITH_AUTH = 'withAuth'
export const AUTH_WITH_OUT_CONTENT_TYPE_MULTIPART = 'authWithoutContentTypeMultipart'
