import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { KTSVG, } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import EditEmployee from './EditEmployee'
import Pagination from '../../../components/Pagination'
import CallFor from '../../../routing/CallFor'
import Select from 'react-select';

const employeeBreadCrumbs: Array<PageLink> = [
  {
    title: 'Management',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const statuses = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' }
]


type UserSubmitForm = {
  EmployeeName: string,
  EmployeeCode: string,
  designation: string,
  role: string,
  mobileNumber: number,
  selectstatus: string,
  userName: string,
  password: string

}

const Employee = () => {

  const modalRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<HTMLDivElement>(null);
  const [designation, setDesignation] = useState<any[]>([]);
  const [employeedata, setEmployeedata] = useState({
    EmployeeName: "",
    EmployeeName_error: '',
    EmployeeCode: "",
    EmployeeCode_error: '',
    designation: '',
    designation_error: '',
    role: '',
    role_error: '',
    mobileNumber: '',
    mobileNumber_error: '',
    selectstatus: '',
    // selectstatus_error:'',
    userName: '',
    userName_error: '',
    password: '',
    password_error: ''
  });
  const [allemployeData, setAllemployeData] = useState<any[]>([]);
  const [editEmployeeData, setEditEmployeeData] = useState<any>({});
  const [employeeSearch, setEmployeeSearch] = useState<any>({
    EmplName: '',
    status: ''
  })
  const [page, setPage] = useState<number>(0);
  const [totalPageNo, setTotalPageNo] = useState<number>(1);
  // const [loading, setLoading] = useState<Boolean>(false);
  // const [error, setError] = useState<any>();
  const [isClear, setIsClear] = useState(false);
  const [deleteId, setDeleteId] = useState()
  const deleteModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getEmployeeDetail();

  }, [isClear, page])

  useEffect(() => {
    designationList();
  }, [])

  const designationList = async () => {
    const response = await CallFor('api/admin/v1/designations/findAll', 'GET', null, 'Auth')
    if (response.status === 200) {
      const res = await response.json();
      const states = await res.data.map(
        (d: { id: any; designationName: any }) => ({
          value: d.id,
          label: d.designationName
        })
      );
      setDesignation(states);
    }
  }

  const { handleSubmit, control } = useForm<UserSubmitForm>({ mode: 'onChange' });

  const addEmployeeModal = () => {
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    modalRef.current!.style.display = "block"
    modalRef.current!.style.paddingLeft = "0px"
    modalRef.current!.classList.add("show")
    modalRef.current!.setAttribute("aria-modal", "true")
    modalRef.current!.setAttribute("role", "dialog")
    modalRef.current!.removeAttribute("aria-hidden")
  }

  const closeEmployeeModal = () => {
    setEmployeedata({
      EmployeeName: "",
      EmployeeName_error: '',
      EmployeeCode: "",
      EmployeeCode_error: '',
      designation: '',
      designation_error: '',
      role: '',
      role_error: '',
      mobileNumber: '',
      mobileNumber_error: '',
      selectstatus: '',
      userName: '',
      userName_error: '',
      password: '',
      password_error: '',
      // selectstatus_error:''
    });
    modalRef.current!.style!.display = "none"
    modalRef.current!.classList.remove("show")
    modalRef.current!.removeAttribute("aria-modal")
    modalRef.current!.removeAttribute("role")
    modalRef.current!.setAttribute("aria-hidden", "true");
    const divElement = document.getElementsByClassName('modal-backdrop')
    divElement[0].remove()
  }

  const editEmployeeModal = () => {
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    editRef.current!.style.display = "block"
    editRef.current!.style.paddingLeft = "0px"
    editRef.current!.classList.add("show")
    editRef.current!.setAttribute("aria-modal", "true")
    editRef.current!.setAttribute("role", "dialog")
    editRef.current!.removeAttribute("aria-hidden")
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const validationField = [event.target.name] + '_error'
    setEmployeedata({ ...employeedata, [event.target.name]: event.target.value, [validationField]: '' })
  }

  const validationSchema = () => {
    let isValid = true;

    if (employeedata.EmployeeName === '') {
      setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "Enter employee name" }))
      isValid = false
    } else
      if (employeedata.EmployeeName.length < 2) {
        setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "Minimum 2 characters" }))
        isValid = false
      } else
        if (employeedata.EmployeeName.length > 50) {
          setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "maximum 50 characters" }))
          isValid = false
        } else
          if (employeedata.EmployeeName.match(/[0-9]/)) {
            setEmployeedata((prev: any) => ({ ...prev, EmployeeName_error: "Only alphabets allowed" }))
            isValid = false
          }
    if (employeedata.EmployeeCode === '') {
      setEmployeedata((prev: any) => ({ ...prev, EmployeeCode_error: "Enter Employee code" }))
      isValid = false
    }
    if (employeedata.designation === '') {
      setEmployeedata((prev: any) => ({ ...prev, designation_error: "Select designation" }))
      isValid = false
    }
    if (employeedata.role === '') {
      setEmployeedata((prev: any) => ({ ...prev, role_error: "Enter role" }))
      isValid = false
    }
    if (employeedata.mobileNumber === '') {
      setEmployeedata((prev: any) => ({ ...prev, mobileNumber_error: "Enter mobile number" }))
      isValid = false
    } else
      if (employeedata.mobileNumber.match(/[a-zA-Z]/) || employeedata.mobileNumber.match(/[.*+?^${}@#=%()|[\]\\]/)) {
        setEmployeedata((prev: any) => ({ ...prev, mobileNumber_error: "Mobile no must be digit" }))
        isValid = false
      } else
        if (employeedata.mobileNumber.length < 10) {
          setEmployeedata((prev: any) => ({ ...prev, mobileNumber_error: "Enter 10 digit mobile number" }))
          isValid = false
        } else
          if (employeedata.mobileNumber.length > 10) {
            setEmployeedata((prev: any) => ({ ...prev, mobileNumber_error: "Mobile number is invalid" }))
            isValid = false
          }
    if (employeedata.userName === '') {
      setEmployeedata((prev: any) => ({ ...prev, userName_error: "Enter user name" }))
      isValid = false
    }
    if (employeedata.password === '') {
      setEmployeedata((prev: any) => ({ ...prev, password_error: "Enter password" }))
      isValid = false
    }
    // if (employeedata.selectstatus === '') {
    //   setEmployeedata((prev:any)=>({...prev, selectstatus_error: "Select Staus"}))
    //   isValid = false
    // }
    return isValid
  }

  const submitHandler = async () => {

    if (validationSchema()) {
      const jsondata = {
        name: employeedata.EmployeeName,
        designationId: employeedata.designation,
        mobile: employeedata.mobileNumber,
        code: employeedata.EmployeeCode,
        role: employeedata.role,
        username: employeedata.userName,
        password: employeedata.password,
        status: 'ACTIVE'
      }
      const data = JSON.stringify(jsondata)
      const response = await CallFor('api/admin/v1/employees', 'POST', data, 'Auth')
      if (response.status === 200) {
        getEmployeeDetail();
        closeEmployeeModal();
      }

    }
  }

  const editEmployeedetails = async (id: any) => {
    editEmployeeModal();
    const response = await CallFor('api/admin/v1/employees/' + id, 'GET', null, 'Auth')
    if (response.status === 200) {
      const res = await response.json();
      setEditEmployeeData({
        code: res.code,
        createdBy: res.createdBy,
        createdDate: res.createdDate,
        designationId: res.designationId,
        designationName: res.designationName,
        id: res.id,
        mobile: res.mobile,
        name: res.name,
        role: res.role,
        status: res.status,
        password: '',
        updatedBy: res.updatedBy,
        updatedDate: res.updatedDate,
        username: res.username,
        usersLoginId: res.usersLoginId,
        password_error: '',
        username_error: '',
        name_error: '',
        code_error: '',
        designationId_error: '',
        role_error: '',
        mobile_error: '',
        status_error: ''
      })
    }
  }

  const statusSearchHandler = (event: any) => {
    if (event !== null && event.value !== undefined) {
      setEmployeeSearch({ ...employeeSearch, status: event.value, status_error: '' });
    }
    else {
      setEmployeeSearch({ ...employeeSearch, status: '' });
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const validationField = [event.target.name] + '_error'
    setEmployeeSearch({ ...employeeSearch, [event.target.name]: event.target.value, [validationField]: '' })
  }

  const validationFun = () => {
    if (employeeSearch.EmplName === '' && employeeSearch.status === '') {
      setEmployeeSearch((prev: any) => ({ ...prev, EmplName_error: "Enter employee name", status_error: 'Select status' }))
      return false
    }
    return true
  }

  const submitSearch = () => {
    if (validationFun()) {
      setPage(0);
      getEmployeeDetail()
    }
  }

  const getEmployeeDetail = async () => {
    setEmployeeSearch((prev: any) => ({ ...prev, EmplName_error: '', status_error: '' }))
    try {
      // setLoading(true)
      const response = await CallFor('api/admin/v1/employees?name=' + employeeSearch.EmplName + "&status=" + employeeSearch.status + '&size=10&page=' + page, 'GET', null, 'Auth')
      if (response.status === 200) {
        const res = await response.json();
        if (res.length === 0) {
          setPage(0);
          setTotalPageNo(0);
        }
        setAllemployeData(res.data.content);
        setTotalPageNo(Number(res.data.pages))

      }
    } catch (error) {
      // setError(error);
    }
    // setLoading(false);
    setIsClear(false);

  }
  const clearSearch = () => {
    setPage(0);
    setEmployeeSearch((prev: any) => ({ ...prev, EmplName: "", status: "", EmplName_error: '', status_error: '' }))
    getEmployeeDetail();
    setIsClear(true)
  }


  const designationSelect = (event: any) => {
    if (event !== null && event.value !== undefined) {
      setEmployeedata({ ...employeedata, designation: event.value, designation_error: '' });
    }
    else {
      setEmployeedata({ ...employeedata, designation: '' });
    }
  };

  const deletePlanModal = (usersLoginId: any) => {
    setDeleteId(usersLoginId)
    const divElement = document.createElement("div")
    divElement.classList.add("modal-backdrop")
    divElement.classList.add("fade")
    divElement.classList.add("show")
    const body = document.getElementById("kt_app_body")
    body?.appendChild(divElement)
    deleteModalRef.current!.style.display = "block"
    deleteModalRef.current!.style.paddingLeft = "0px"
    deleteModalRef.current!.classList.add("show")
    deleteModalRef.current!.setAttribute("aria-modal", "true")
    deleteModalRef.current!.setAttribute("role", "dialog")
    deleteModalRef.current!.removeAttribute("aria-hidden")
  }

  const closeKpPlanDeleteModal = () => {
    deleteModalRef.current!.style!.display = "none"
    deleteModalRef.current!.classList.remove("show")
    deleteModalRef.current!.removeAttribute("aria-modal")
    deleteModalRef.current!.removeAttribute("role")
    deleteModalRef.current!.setAttribute("aria-hidden", "true");
    const divElement = document.getElementsByClassName('modal-backdrop')
    divElement[0].remove()
  }

  const deleteEmployeeDetail = async () => {
    setAllemployeData(allemployeData.filter((item) => item.usersLoginId !== deleteId));
    const response = await CallFor('api/admin/v1/employees/' + deleteId, 'DELETE', null, 'Auth')
    if (response.status === 204) {
      getEmployeeDetail();
      closeKpPlanDeleteModal();
    }
  }


  return (
    <div>

      <PageTitle breadcrumbs={employeeBreadCrumbs}>Employee Details</PageTitle>

      <div className='card mb-5 p-5'>
        <div className='row'>


          <div className="mb-3 col-lg-3">
            <label className="form-label">Employee Name</label>
            <input
              type="text"
              name='EmplName'
              className={`form-control ${employeeSearch?.EmplName_error === "" ? '' : 'is-invalid'}`}
              placeholder="Enter Employee Name"
              value={employeeSearch.EmplName}
              onChange={(e) => handleSearch(e)}
            />
            <p id='error_1' className='errormsg' >{employeeSearch?.EmplName_error}</p>
          </div>
          <div className="col-lg-3">
            {/* <label className='form-label'>Status</label>
            <select 
              // className='form-select text-muted'
              className={`form-select text-muted ${employeeSearch?.status_error === "" ? '' : 'is-invalid'}`}
               name='status'
               onChange={(e)=>handleSearch(e)}
               value={employeeSearch.status}
            >
              <option value=''>Select a Status...</option>
              <option value='ACTIVE'>ACTIVE</option>
              <option value='INACTIVE'>INACTIVE</option>
            </select>
                 <p id='error_1' className='errormsg' >{employeeSearch?.status_error }</p> */}
            <label className='form-label'>Status</label>
            <Controller
              name='selectstatus'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  isSearchable={true}
                  placeholder='Select Status'
                  // className={designationSearch.stat_error ? 'error-border' : ''}
                  value={statuses.filter(
                    (obj) => obj.value === employeeSearch.status
                  )}
                  onChange={statusSearchHandler}
                  id='status'
                  options={statuses}
                  className={employeeSearch?.status_error ? 'error-border' : ''}
                />
              )}
            />
            <p id='error_1' className='errormsg' >{employeeSearch?.status_error}</p>
          </div>
          <div className="mb-3 col-lg-3 mt-8">
            <button type='submit' className='btn btn-primary' onClick={submitSearch} >Search</button>
            <button type='submit' className='btn btn-danger clear-btn' onClick={clearSearch} >Clear</button>
          </div>
        </div>
      </div>

      <div className='card mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Employee Details</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button type="button" className="btn btn-primary btn-sm" data-bs-target="#kt_modal_1" onClick={addEmployeeModal}>Add Employee</button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">

                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-140px'>Code</th>
                  <th className='min-w-120px'>Designation</th>
                  <th className='min-w-120px'>role</th>
                  <th className='min-w-120px'>Mobile</th>
                  <th className='min-w-120px'>Status</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              {
                allemployeData.length > 0
                  ?
                  allemployeData.map((data, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>{data.name}</div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>{data.code}</div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>{data.designationName}</div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>{data.role} </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>{data.mobile}</div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>{data.status}</div>
                          </td>
                          <td>
                            {
                              data.status === 'ACTIVE' ?
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => { editEmployeedetails(data.id) }}
                                  >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                  </a>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    // onClick={() => { deleteEmployeeDetail(data.usersLoginId) }}
                                    // deletePlanModal
                                    onClick={() => { deletePlanModal(data.usersLoginId) }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </a>
                                </div>
                                :
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => { editEmployeedetails(data.id) }}
                                  >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                  </a>
                                </div>
                            }
                          </td>
                        </tr>

                      </tbody>
                    )
                  })
                  :
                  <tbody><tr> <td colSpan={9}> <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6> </td> </tr> </tbody>
              }

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}

      <div className="modal fade" ref={modalRef} tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Employee</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={closeEmployeeModal}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <div className='row'>

                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="mb-3 col-lg-12">
                    <label className="form-label">Employee Name*</label>
                    <input
                      type="text"
                      placeholder="Employee Name"
                      className={`form-control ${employeedata?.EmployeeName_error === "" ? '' : 'is-invalid'}`}
                      value={employeedata.EmployeeName}
                      name='EmployeeName'
                      onChange={(e) => handleChange(e)}
                      id='designation'
                    />
                    <p id='error_1' className='errormsg' >{employeedata?.EmployeeName_error}</p>
                  </div>
                  <div className="col-lg-12">
                    <label className="form-label">Employee Code*</label>
                    <input
                      type="text"
                      name='EmployeeCode'
                      className={`form-control ${employeedata?.EmployeeCode_error === "" ? '' : 'is-invalid'}`}
                      value={employeedata.EmployeeCode}
                      placeholder="Enter Employee Code"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <p id='error_1' className='errormsg' >{employeedata?.EmployeeCode_error}</p>
                  <div className="col-lg-12">
                    <label className='form-label'>Designation</label>
                    <Controller
                      name='designation'
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          isSearchable={true}
                          placeholder='Select Designation'
                          value={designation.filter(
                            (obj) => obj.value === employeedata.designation)}
                          onChange={designationSelect}
                          id='designation'
                          options={designation}
                          className={employeedata.designation_error ? 'error-border' : ''}
                        />
                      )}
                    />
                  </div>
                  <p id='error_1' className='errormsg' >{employeedata?.designation_error}</p>
                  <div className="col-lg-12">
                    <label className="form-label">Role*</label>
                    <input
                      type="text"
                      name='role'
                      className={`form-control ${employeedata?.role_error === "" ? '' : 'is-invalid'}`}
                      value={employeedata.role}
                      placeholder="Enter Employee role"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <p id='error_1' className='errormsg' >{employeedata?.role_error}</p>
                  <div className="col-lg-12">
                    <label className="form-label">Mobile Number*</label>
                    <input
                      type="text"
                      className={`form-control ${employeedata?.mobileNumber_error === "" ? '' : 'is-invalid'}`}
                      placeholder="Enter Mobile Number"
                      value={employeedata.mobileNumber}
                      name='mobileNumber'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <p id='error_1' className='errormsg' >{employeedata?.mobileNumber_error}</p>
                  <div className="col-lg-12">
                    <label className="form-label">User Name*</label>
                    <input
                      type="text"
                      name="userName"
                      className={`form-control ${employeedata?.userName_error === "" ? '' : 'is-invalid'}`}
                      value={employeedata.userName}
                      placeholder="Enter UserName"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <p id='error_1' className='errormsg' >{employeedata?.userName_error}</p>
                  <div className="col-lg-12">
                    <label className="form-label">Password*</label>
                    <input
                      type="password"
                      className={`form-control ${employeedata?.password_error === "" ? '' : 'is-invalid'}`}
                      placeholder="Enter Password"
                      value={employeedata.password}
                      name='password'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <p id='error_1' className='errormsg' >{employeedata?.password_error}</p>
                  {/* <div className="col-lg-12">
                    <label className='form-label'>Status</label>
                    <select
                      className={`form-select text-muted ${employeedata?.selectstatus_error === "" ? '' : 'is-invalid'}`}
                      name='selectstatus'
                      id='selectstatus'
                      value={employeedata.selectstatus}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value=''>Select a Status...</option>
                      <option value='ACTIVE'>ACTIVE</option>
                      <option value='INACTIVE'>INACTIVE</option>
                    </select>
                  </div>
                  <p id='error_1' className='errormsg' >{employeedata?.selectstatus_error}</p> */}
                  <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                    <button type="submit" className="btn btn-primary"
                      // data-bs-dismiss="modal"
                      id='saveBtn'
                    >
                      Save Employee
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" ref={deleteModalRef} tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog modelLower">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Employee Detail</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                // data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  href='#'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={closeKpPlanDeleteModal}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <p className='inactiveName'>Are you sure you want to delete this employee ?</p>
            </div>
            <div className='modal-footer py-1 px-3 ms-auto w-100 deleteButton'>
              <button className="btn btn-primary btn-sm" onClick={deleteEmployeeDetail}>Yes</button>
              <button className="btn btn-secondary btn-sm" onClick={closeKpPlanDeleteModal}>No</button>
            </div>
          </div>
        </div>
      </div>

      {
        totalPageNo > 0 &&
        <Pagination
          totalCount={totalPageNo}  //total number of data
          siblingCount={1} // number of extra elements around avtive page
          currentPage={page} //current active page
          onPageChange={setPage} //page change handler state
          pageSize={10} // data per page
        />}
      <EditEmployee editRef={editRef} editEmployeeData={editEmployeeData} designation={designation} setEditEmployeeData={setEditEmployeeData} getEmployeeDetail={getEmployeeDetail} />

    </div>
  )
}
export default Employee
