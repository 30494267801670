function extractUniqueNumericToStrings(arr: any, seen = new Set()) {

    if (arr !== 'undefined') {
        if (Array.isArray(arr)) {
            arr.forEach(item => {
                if (Array.isArray(item)) {
                    extractUniqueNumericToStrings(item, seen);
                } else if (typeof item === 'string' && /^\d+$/.test(item)) {
                    seen.add(item);
                }
            });

        } else if (typeof arr === 'string') {
            arr = arr.split(',')
            arr.forEach((item: any) => {
                if (Array.isArray(item)) {
                    extractUniqueNumericToStrings(item, seen);
                } else if (typeof item === 'string' && /^\d+$/.test(item)) {
                    seen.add(item);
                }
            });
        }
        return Array.from(seen);
    }
    else {
        return arr;
    }
}

export default extractUniqueNumericToStrings;