import { useState, } from 'react';
import errorData from '../../_metronic/i18n/messages/error.json'; // Import JSON

interface Error {
  subcode: number;
  code: string;
  message: string;
}

const ApiErrorMessage = async (subCode: number, code: string): Promise<string> => {
  let error;
  if (code != undefined && code != null && code != "") {
    // Find matching error message based on subcode and code
    error = errorData.errors.find(
      (err: Error) => err.subcode === subCode && err.code === code
    );
  } else {
    error = errorData.errors.find(
      (err: Error) => err.subcode === subCode
    );
  }

  // Return the error message if found, otherwise return a default message
  const message = error ? error.message : 'Error message not found';
  console.log("ApiErrorMessage", subCode, code, message);
  return message;

};


export default ApiErrorMessage;

