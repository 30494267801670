//import { UseFormSetError } from 'react-hook-form';
import CallFor from '../../../../routing/CallFor';

export interface PinCodeTypes {
    area: string;
    city: string;
    pincode: string;
    state: string;
    stateId: string;
    pincodeId: string
}


const GetSearchPinCodeHandler = () => {
    const searchPinCodeHandler = async (inputValue: React.ChangeEvent<HTMLInputElement>, setPinCodeValue: React.Dispatch<React.SetStateAction<string | number>>,
        //setError: UseFormSetError<SubmitHandlerStepTwo>, 
        setOptions: React.Dispatch<React.SetStateAction<PinCodeTypes[]>>,
        message: string,
        setSelectedPinCode: React.Dispatch<React.SetStateAction<any>>) => {
        setPinCodeValue(inputValue?.target?.value);
        setSelectedPinCode({ pin: "", city: "", state: "", stateId: "", area: "", pinCodeId: "" })
        if (inputValue?.target?.value?.trim()?.length === 6) {
            const productsRes = await CallFor(
                `api/admin/v1/businesses/pincode?pincode=${inputValue?.target?.value}`,
                'GET',
                null,
                'Auth'
            );

            const postRes = await productsRes.json()
            if (productsRes.status === 200) {
                if (postRes?.data?.length === 0) {
                    console.log("postRes ", message)
                    alert("PinCode Not Found")
                    //     setError('pincode', {
                    //         type: 'required',
                    //         message: message
                    //     });
                } else {
                    setOptions(postRes?.data)
                }
            }
        }
    };
    return {
        searchPinCodeHandler
    }
}

export default GetSearchPinCodeHandler