// import React from 'react'
import GlobalProperties from "./GlobalProperties";
import { UrlType } from "./UrlType";
import { handleError } from "./ExceptionHandler";
import { AUTH, AUTH_WITH_CONTENT_TYPE, AUTH_WITH_FORM_DATA, AUTH_WITH_OUT_CONTENT_TYPE_MULTIPART, REG_AUTH, REG_WITHOUT_CONTENT, TEST, WITH_AUTH, WITH_OUT_AUTH } from "../../constants/global";
const getToken = () => {
    const user: any = localStorage.getItem('kt-auth-react-v') || null;
    const data: any = JSON.parse(user)
    return data.token;
}

const CallFor = async (requestUrl: string, method: string, data: string | any, headerType: string | FormData | null) => {
    let url = '';
    if (GlobalProperties.environment === UrlType.LIVE) {
        url = GlobalProperties.urlParam + requestUrl;
    } else if (GlobalProperties.environment === UrlType.TEST) {
        url = GlobalProperties.testParam + requestUrl;
    } else {
        url = GlobalProperties.localUrlParam + requestUrl;
    }
    const header = new Headers();
    if (headerType === REG_AUTH || headerType === REG_WITHOUT_CONTENT) {
        header.append('authorization', 'Bearer ' + getToken());
    }
    if (headerType === WITH_OUT_AUTH) {
        header.append('Content-Type', 'application/json');
    } else if (headerType === WITH_AUTH) {
        header.append('curUserId', '123456')
        header.append('Content-Type', 'application/json')
    }
    else if (headerType === AUTH) {


        header.append('authorization', 'Bearer ' + getToken());
        header.append('curUserId', '123456')
        header.append('Content-Type', 'application/json');
    }
    else if (headerType === AUTH_WITH_FORM_DATA) {
        header.append('authorization', 'Bearer ' + getToken());
    }
    else if (headerType === AUTH_WITH_CONTENT_TYPE) {
        header.append('Authorization', 'Bearer ' + getToken());
        header.append('Content-Type', 'application/json');
    }
    else if (headerType === TEST) {
        header.append('curUserId', '123456');
        header.append('Z-AUTH-USERID', '1076088465346723840')
        header.append('Content-Type', 'application/json');
    } else if (headerType === AUTH_WITH_OUT_CONTENT_TYPE_MULTIPART) {
        header.append('Authorization', 'Bearer ' + getToken());
        header.append('Content-Type', 'multipart/form-data');
    }

    const requestOptions: any = {
        method: method,
        headers: header,
        body: data,
        cache: "force-cache",
    };
    const returnResponse = {
        status: 500,
        message: 'Server Error Found'
    };
    try {
        return await fetch(
            url, requestOptions
        )
        // .then((res) => {
        //   return res;
        // }).catch((error) => {
        //   handleError(error);
        //   return returnResponse;
        // });
    } catch (error: any) {
        return handleError(error);
    }
}

export default CallFor