import { useEffect, useRef, useState } from 'react'
import CallFor from '../../../../routing/CallFor'
import { AUTH, GET } from '../../../../../constants/global'
import { KTSVG } from '../../../../../_metronic/helpers';

import extractUniqueNumericToStrings from '../extractUniqueNumericToStrings';
import numberStringToIdArray from '../numberStringToIdArray';

const CategoryModal = (props: any) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const [selectedCategory, setSelectedCategory] = useState<any | null>("");
    const [searchCategoryName, setSearchCategoryName] = useState("");
    const [categories, setCategories] = useState([]);
    const [searchCategoriesData, setSearchCategoriesData] = useState([]);
    const [categoryData, setCategoryData] = useState<any | null>("");
    const [nextBtnDisable, setNextBtnDisable] = useState(true)






    const getCategoryData = async () => {
        await CallFor(
            `api/admin/v1/businesses/category`,
            GET,
            null,
            AUTH
        ).then(async (response) => {
            const json1Response = await response.json();
            const allCategory = json1Response?.data

            setCategories(allCategory);
            setSearchCategoriesData(allCategory)
        });
    };

    const closeKpPlanModal = () => {

        modalRef.current!.classList.remove("show")
        modalRef.current!.style!.display = "none"
        modalRef.current!.removeAttribute("aria-modal")
        modalRef.current!.removeAttribute("role")
        modalRef.current!.setAttribute("aria-hidden", "true");
        const divElement = document.getElementsByClassName('modal-backdrop');
        divElement[0].remove();
        props.setModalCategory(false);
    }
    useEffect(() => {

        if (props.categoryId !== "" && props.categoryId) {
            setSelectedCategory(props.categoryId)
        }
        else {
            setSelectedCategory([])
        }
        if (props.modalCategory === true) {

            props.setModalCategory(true)
            const divElement = document.createElement("div")
            divElement.classList.add("modal-backdrop")
            divElement.classList.add("fade")
            divElement.classList.add("show")
            const body = document.getElementById("kt_app_body")
            body?.appendChild(divElement)
            modalRef.current!.style.display = "block"
            modalRef.current!.style.paddingLeft = "0px"
            modalRef.current!.classList.add("show")
            modalRef.current!.setAttribute("aria-modal", "true")
            modalRef.current!.setAttribute("role", "dialog")
            modalRef.current!.removeAttribute("aria-hidden")

            getCategoryData();
        }
        //react-hooks/exhaustive-deps
    }, [props.modalCategory]);
    const searchHandler = (event: any) => {
        if (event.target.value) {
            setSearchCategoryName(event.target.value.toLowerCase())
            setCategories(
                searchCategoriesData?.filter((item: any) => item.name?.toLowerCase()?.includes(event.target.value.toLowerCase()))
            );
        } else {
            setSearchCategoryName("");
            setCategories(searchCategoriesData);
        }
    }
    const isSelected = (id: any) => {
        console.log("isSelected selectedCategory:", id, selectedCategory)
        if (selectedCategory !== 'undefined') {
            const dataArray = extractUniqueNumericToStrings(selectedCategory);
            return dataArray.some((item: any) => item === id.toString());
        }
        else
            return false;
    }

    const businessCategorySelectHandler = async (newItem: any) => {
        console.log("addItem : ", newItem.id)
        console.log("selectedCategory:", selectedCategory)
        let dataArray;
        debugger;
        if (selectedCategory && selectedCategory.length > 0) {
            if (selectedCategory.includes(',')) {
                dataArray = extractUniqueNumericToStrings(selectedCategory);
            }
            else {
                dataArray = selectedCategory;
            }
        }
        else {
            dataArray = []
        }
        //const dataArray = ? (selectedCategory.includes(',') ? extractUniqueNumericToStrings(selectedCategory) : selectedCategory) : [];
        const itemExists = dataArray.some((item: any) => item === newItem.id.toString());
        //console.log("itemExists : ", itemExists)
        if (!itemExists) {
            setSelectedCategory((prevItems: string[] = []) => [...prevItems, newItem.id.toString()]);

            //setCategoryData((prevItems: any) => [...prevItems, newItem]);
            setCategoryData((prevItems: any) => {
                if (prevItems.length === 0) {
                    // Add the new category and return the updated array
                    return [...prevItems, newItem];
                }
                // Ensure prevItems is an array
                if (!Array.isArray(prevItems)) {
                    console.error('prevItems is not an array:', prevItems);
                    return [];
                }
                // Check if the category already exists (optional)
                const categoryExists = prevItems.some(item => item.id === newItem.id);
                if (categoryExists) {
                    console.warn('Category with this ID already exists.');
                    return prevItems;
                }

                // Add the new category and return the updated array
                return [...prevItems, newItem];
            });
        }
        setNextBtnDisable(false)

    }
    // Working Code 
    // const businessCategorySelectHandler = async (newItem: any) => {

    //     const dataArray = selectedCategory && selectedCategory.length > 0 ? (selectedCategory.includes(',') ? extractUniqueNumericToStrings(selectedCategory).join(',').split(",") : selectedCategory) : [];
    //     const itemExists = dataArray.some((item: any) => item === newItem.id.toString());

    //     if (!itemExists) {

    //         setSelectedCategory((prevItems: string[] = []) => [...prevItems, newItem.id.toString()]);

    //         //setCategoryData((prevItems: any) => [...prevItems, newItem]);
    //         setCategoryData((prevItems: any) => {
    //             // Ensure prevItems is an array
    //             const itemsArray = Array.isArray(prevItems) ? prevItems : [];

    //             // Check if the category already exists
    //             const categoryExists = itemsArray.some(item => item.id === newItem.id);
    //             if (categoryExists) {
    //                 console.warn('Category with this ID already exists.');
    //                 return itemsArray;
    //             }

    //             // Add the new category and return the updated array
    //             return [...itemsArray, newItem];

    //         });
    //     }
    //     setNextBtnDisable(false)

    // }
    const removeCategory = (itemId: string) => {
        const dataArray = extractUniqueNumericToStrings(selectedCategory);
        const itemExists = dataArray.some((item: any) => item === itemId);
        if (itemExists === true) {
            setCategoryData((prevItems: any) => {
                // Ensure prevItems is an array
                const itemsArray = Array.isArray(prevItems) ? prevItems : [];
                // Check if the category with the given id exists
                const categoryExists = itemsArray.some(item => item.id === itemId.toString());

                if (!categoryExists) {
                    console.warn('Category with this ID does not exist.');
                    return itemsArray;  // No update if the category doesn't exist
                }

                // Remove the category with the given id
                const updatedArray = itemsArray.filter(item => item.id.toString() !== itemId.toString());
                // Return the updated array
                return updatedArray;
            });
            setSelectedCategory((prevItems: any) => prevItems.filter((item: any) => item !== itemId));
        }
    }
    const removeCategory1 = (itemId: string) => {
        const dataArray = extractUniqueNumericToStrings(selectedCategory);
        const itemExists = dataArray.some((item: any) => item === itemId);
        console.log("ddddd", itemExists, itemId, dataArray)
        if (itemExists === true) {
            setCategoryData((prevItems: any) => {
                // Ensure prevItems is an array
                const itemsArray = Array.isArray(prevItems) ? prevItems : [];
                // Check if the category with the given id exists
                const categoryExists = itemsArray.some(item => item.id === itemId.toString());

                if (!categoryExists) {
                    console.warn('Category with this ID does not exist.');
                    return itemsArray;  // No update if the category doesn't exist
                }

                // Remove the category with the given id
                const updatedArray = itemsArray.filter(item => item.id.toString() !== itemId.toString());
                // Return the updated array
                return updatedArray;
            });
            setSelectedCategory((prevItems: any) => prevItems.filter((item: any) => item !== itemId));
            // setCategoryData((prevItems: any) => {
            //     // Ensure prevItems is an array
            //     const itemsArray = Array.isArray(prevItems) ? prevItems : [];
            //     console.log("ddddd2222", itemsArray, prevItems)
            //     // Check if the category with the given id exists
            //     const categoryExists = itemsArray.some(item => item.id === itemId.toString());
            //     console.log("ddddd2222333", categoryExists)
            //     if (!categoryExists) {
            //         console.warn('Category with this ID does not exist.');
            //         return itemsArray;  // No update if the category doesn't exist
            //     }

            //     // Remove the category with the given id
            //     const updatedArray = itemsArray.filter(item => item.id.toString() !== itemId.toString());
            //     console.log("ddddd2222333 updatedArray", updatedArray)
            //     // Return the updated array
            //     return updatedArray;
            // });


            // setSelectedCategory((prevItems: any) => {
            //     if (Array.isArray(prevItems)) {
            //         console.log("ddddd", itemExists, itemId, dataArray, prevItems)
            //         return prevItems.filter((item: any) => item !== itemId);
            //     }
            //     return prevItems; // Or return an empty array if that's more appropriate
            // });


            // setCategoryData((prevItems: any) => {
            //     // Ensure prevItems is an array
            //     const itemsArray = Array.isArray(prevItems) ? prevItems : [];
            //     console.log(itemsArray)
            //     // Check if the category with the given id exists
            //     const categoryExists = itemsArray.some(item => item.id.toString() === itemId.toString());
            //     console.log(categoryExists)
            //     if (!categoryExists) {
            //         console.warn('Category with this ID does not exist.');
            //         return itemsArray;  // No update if the category doesn't exist
            //     }

            //     // Remove the category with the given id
            //     const updatedArray = itemsArray.filter(item => item.id.toString() !== itemId);

            //     // Return the updated array
            //     return updatedArray;
            // });

            //setSelectedCategory((prevItems: any) => prevItems.filter((item: any) => item !== itemId));
        }
        // else {

        // }
        //setSelectedCategory([])


    }
    // Function to check if there's a match
    const findMatches = (arr1: any[], arr2: { id: string }[]): boolean => {
        // Convert arr1 to a Set for faster lookups
        const setArr1 = new Set(arr1.map(item => item.toString())); // Ensure type consistency

        // Check if any categoryId in arr2 is present in setArr1
        const condition1 = arr2.some(item => setArr1.has(item.id));

        // Check if all elements in arr1 are present in categoryId of arr2
        const setArr2Ids = new Set(arr2.map(item => item.id));
        const condition2 = arr1.every(item => setArr2Ids.has(item.toString()));

        // Return true if both conditions are met
        return condition1 && condition2;
    };

    const nextBtn = () => {
        if (selectedCategory !== null) {
            // const category = extractUniqueNumericToStrings(selectedCategory)
            props.setCategory([]);
            props.setCategoryId("")
            const data = findMatches(extractUniqueNumericToStrings(selectedCategory), numberStringToIdArray(props.categoryId))
            if (data === false) {
                props.setProductId("")
                props.setProduct([]);
            }
            props.setCategory(categoryData);
            props.setCategoryId(extractUniqueNumericToStrings(selectedCategory).join(','))
            closeKpPlanModal();
            props.nextBtnCall();

        }
    }
    return (
        <div className="modal fade" ref={modalRef} tabIndex={-1} id="kt_modal_1" >
            <div className="modal-dialog ">
                <div className="modal-content">
                    <div className="modal-header p-4">
                        <h5 className="modal-title">Select your Category</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            // data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={closeKpPlanModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            {/* <form onSubmit={handleSubmit(submitHandler)}> */}
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="searchInput" className="form-label">
                                    {"Search Category"}
                                </label>
                                <div className="bgSearch flex justify-between items-center border-2 border-gray-300 rounded-lg pl-1 pr-3  w-7/12 w-full">
                                    <div className="relative w-full">
                                        {/* <input type="text" id="floating_outlined_0.8931948193864565_undefined" className="block px-2.5 pb-2.5 pt-3 w-full text-lg bg-white  text-gray-900  border-gray-300 focus:border-blue-600  bg-transparent rounded-lg border appearance-none focus:outline-none focus:ring-0 peer border-0 focus:!border-0" placeholder="Search Category" value="" /> */}



                                        <input
                                            type="text"
                                            id="searchInput"
                                            placeholder={"Search Category"}
                                            className=" form-control border-0 focus:!border-0
                                            block px-2.5 pb-2.5 pt-3 w-full text-lg  text-gray-900  border-gray-300-custom focus:border-blue-600  bg-transparent rounded-lg border appearance-none focus:outline-none focus:ring-0 peer "
                                            onChange={(e) => searchHandler(e)}
                                            value={searchCategoryName}
                                        />
                                    </div>
                                    {searchCategoryName.trim() && searchCategoryName.trim().length > 0 ? (
                                        <i
                                            className="fas fa-times text-1B74E4 w-5 h-5 ml-2 cursor-pointer mt-5px"
                                            onClick={(e) => searchHandler(e)}
                                        ></i>
                                    ) : (
                                        <i className="fas fa-search text-muted"></i>
                                    )}


                                </div>

                                <div className="h-310px overflow-auto mt-2">

                                    {
                                        categories.length > 0 ?
                                            categories.map((item: any) => {
                                                return (
                                                    // <div className={`chip w-max rounded-full ${isSelected(item?.id) ? "bg-blue-100 pr-1 flex items-center border-1B74E4" : "border-gray-400 bg-white hover:bg-gray-100 pr-3"}`} onClick={() => { businessCategorySelectHandler(item); }}>
                                                    //     <span>{item.name}</span>
                                                    //     {/* <i className="material-icons">cancel</i> */}
                                                    //     {isSelected(item?.id) && (
                                                    //         <i className='fas fa-times text-1B74E4 ml-2' onClick={(e) => { e.stopPropagation(); removeCategory(item?.id); }}></i>
                                                    //     )}
                                                    // </div>
                                                    <div
                                                        className={`py-2 pl-3 mt-2 rounded-full w-max text-sm text-gray-800-custom font-medium cursor-pointer ${isSelected(item?.id)
                                                            ? "bg-blue-100 pr-1 flex items-center  border-1B74E4"
                                                            : "border border-gray-400 bg-white hover:bg-gray-100 pr-3"
                                                            }`}
                                                        key={item.id}
                                                        onClick={() => { businessCategorySelectHandler(item); }}
                                                    >
                                                        {item.name}
                                                        {isSelected(item?.id) && (
                                                            <div onClick={(e) => { e.stopPropagation(); removeCategory(item?.id); }}>
                                                                <i
                                                                    className="fas fa-times text-1B74E4 w-5 h-5 ml-2 cursor-pointer pt-1"
                                                                ></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="bg-gray-100 p-4">{"Search for anything else!"}</div>
                                    }
                                    {/* components import in company change height className add this className "h-[635px]" */}


                                </div>
                            </div>

                            <div className="modal-footer p-0 ms-auto w-100 border-0 mt-2">
                                <button type="button" className='btn btn-primary btn-sm'
                                    disabled={selectedCategory.length === 0}
                                    onClick={() => { nextBtn() }}
                                >
                                    Selected
                                </button>
                                {/* <button type="submit" className="btn btn-primary">
                                        Create
                                    </button> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CategoryModal