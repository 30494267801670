import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import CallFor from '../../../routing/CallFor';
import { toast } from 'react-toastify';
import Pagination from '../../../components/Pagination';
const PlanUserActivity = () => {
    const [details, setDetails] = useState<Object[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(1);
    const [showData, setShowData] = useState<Boolean>(false);
    const [search, setSearch] = useState<any>({
        startDate: '',
        startDate_error: '',
        endDate: '',
        endDate_error: '',
        mobileNumber: '',
        mobileNumber_error: '',
    })
    const searchHandler = async () => {
        try {
            let jsonData: any = {
                mobileNo: search?.mobileNumber,
                fromDate: search?.startDate,
                toDate: search?.endDate,
                page: page
            }
            const response = await CallFor('api/admin/v1/plan-user-activity', 'POST', JSON.stringify(jsonData), 'Auth');

            if (response.status === 200) {
                const jsonResponse = await response.json();
                if (jsonResponse.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(jsonResponse?.data?.page))
                setDetails(jsonResponse?.data?.content);
            } else if (response.status === 400) {
                toast.error("No Data Found")
                setDetails([]);
                setTotalPageNo(0);
            }
        } catch (error) {
            console.log(error);
        } finally {
        }
    };
    useEffect(() => {
        if (showData) {
            searchHandler()
        }
    }, [page])

    const clearHandler = () => {
        setDetails([]);
        setTotalPageNo(0);
        setSearch((prev: any) => ({
            startDate: '',
            startDate_error: '',
            endDate: '',
            endDate_error: '',
            mobileNumber: '',
            mobileNumber_error: '',
        }))
    }

    const start: any = new Date(search?.startDate);
    const end: any = new Date(search?.endDate);
    const differenceInDays: any = Math.abs((start - end) / (1000 * 60 * 60 * 24));
    console.log(differenceInDays)
    const validationSchema = () => {
        if (search?.startDate === '' && search?.endDate === '' && search?.mobileNumber === '') {
            setSearch((prev: any) => ({ ...prev, startDate_error: "Select StartDate", endDate_error: "Select EndDate", mobileNumber_error: "Enter Mobile Number" }))
            return false
        }
        else if (search?.startDate !== '' && search?.endDate === '') {
            setSearch((prev: any) => ({ ...prev, endDate_error: "Select End Date" }))
            return false
        }
        else if (search?.startDate === '' && search?.endDate !== '') {
            setSearch((prev: any) => ({ ...prev, startDate_error: "Select Start Date" }))
            return false
        }
        else if (search?.mobileNumber !== '' && search.mobileNumber?.length < 10) {
            setSearch((prev: any) => ({ ...prev, mobileNumber_error: "Enter 10 Digit Mobile Number" }))
            return false
        }
        else if (search?.startDate !== '' && search?.endDate !== '' && (differenceInDays > 1)) {
            setSearch((prev: any) => ({ ...prev, startDate_error: "Start Date and End Date difference is only 1 day", endDate_error: "Start Date and End Date difference is only 1 day" }))
            return false
        }
        return true
    }
    console.log(search?.startDate)
    const searchSubmit = () => {
        if (validationSchema()) {
            setPage(0);
            setShowData(true);
            searchHandler()
        }
    }

    const handleSearch = (event: any) => {
        const validationField = [event.target.name] + '_error'
        setSearch({ ...search, [event.target.name]: event.target.value, [validationField]: '' })
        setSearch((prev: any) => ({ ...prev, startDate_error: "", endDate_error: "", mobileNumber_error: "" }))
    }
    function onKeyDownHandler(e: any) {
        allowOnlyNumbers(e)
    }
    function allowOnlyNumbers(event: any) {
        const ctrl = event.ctrlKey || event.metaKey;
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (
            (ctrl && keyCode === 86) || // Ctrl + V
            !ctrl && (
                keyCode === 13 || // Enter
                keyCode === 8 ||  // Backspace
                keyCode === 9 ||  // Tab
                keyCode === 35 || // Home
                keyCode === 36 || // End
                keyCode === 37 || // Left Arrow
                keyCode === 39 || // Right Arrow
                keyCode === 38 || // Up Arrow
                keyCode === 40 ||  // Down Arrow
                keyCode === 46 ||  // Delete
                (keyCode >= 96 && keyCode <= 105) || (keyCode >= 112 && keyCode <= 123))
        ) {
            return;
        }
        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    }
    return (
        <div>
            <PageTitle>Plan User Activity</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Start Date</label>
                        <input
                            name='startDate'
                            type="date"
                            className={`form-control ${search?.startDate_error === "" ? '' : 'is-invalid'}`}
                            value={search?.startDate}
                            placeholder="Start Date"
                            onChange={(e) => handleSearch(e)}
                            max={search?.endDate}
                        />
                        <p id='error_1' className='errormsg' >{search?.startDate_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">End Date</label>
                        <input
                            name='endDate'
                            type="date"
                            className={`form-control ${search?.endDate_error === "" ? '' : 'is-invalid'}`}
                            value={search?.endDate}
                            placeholder="End Date"
                            onChange={(e) => handleSearch(e)}
                            min={search?.startDate}
                        />
                        <p id='error_1' className='errormsg' >{search?.endDate_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Mobile Number</label>
                        <input
                            name='mobileNumber'
                            type="text"
                            placeholder="Mobile Number"
                            className={`form-control ${search?.mobileNumber_error === "" ? '' : 'is-invalid'}`}
                            value={search.mobileNumber}
                            onChange={(e) => handleSearch(e)}
                            maxLength={10}
                            onKeyDown={onKeyDownHandler}
                        />
                        <p id='error_1' className='errormsg' >{search?.mobileNumber_error}</p>
                    </div>
                    <div className="col-lg-12  text-center">
                        <button type='submit' className='btn btn-primary' onClick={searchSubmit}>Search</button>
                        <button type='submit' className='btn btn-danger clear-btn' onClick={clearHandler}>Clear</button>
                    </div>
                </div>
            </div>


            <div className='card mb-5'>
                {<div className='card-body py-3'>
                    {details.length > 0 ?
                        <div className='table-responsive'>
                            <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th className='min-w-150px'>User Details</th>
                                        <th className='min-w-150px'>Plan Name</th>
                                        <th className='min-w-150px'>Catalogue</th>
                                        <th className='min-w-140px'>Company</th>
                                        <th className='min-w-120px'>Connection</th>
                                        <th className='min-w-120px'>Inquiry</th>
                                        <th className='min-w-120px'>Post</th>
                                        <th className='min-w-120px'>Quote</th>
                                        <th className='min-w-120px'>teamMember</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        details?.map((detail: any, i: number) => <tr key={i}>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {detail?.fullName} <br /> {detail?.emailId}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {detail?.planName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {detail?.catalogueDate.split('T')[0]}
                                                    <span className='text-primary fw-bold'>&nbsp;({detail?.catalogueCount})</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center text-nowrap'>
                                                    {detail?.companyDate.split('T')[0]}
                                                    <span className='text-primary fw-bold'>&nbsp;({detail?.companyCount})</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center text-nowrap'>
                                                    {detail?.connectionDate.split('T')[0]}
                                                    <span className='text-primary fw-bold'>&nbsp;({detail?.connectionCount})</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center text-nowrap'>
                                                    {detail?.inquiryDate.split('T')[0]}
                                                    <span className='text-primary fw-bold'>&nbsp;({detail?.inquiryCount})</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center text-nowrap'>
                                                    {detail?.postDate.split('T')[0]}
                                                    <span className='text-primary fw-bold'>&nbsp;({detail?.postCount})</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center text-nowrap'>
                                                    {detail?.quoteDate.split('T')[0]}
                                                    <span className='text-primary fw-bold'>&nbsp;({detail?.quoteCount})</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center text-nowrap'>
                                                    {detail?.teamMemberDate.split('T')[0]}
                                                    <span className='text-primary fw-bold'>&nbsp;({detail?.teamMemberCount})</span>
                                                </div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : <tbody>
                            <tr>
                                <td colSpan={9}>
                                    <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                                </td>
                            </tr>
                        </tbody>
                    }
                </div>
                }
            </div>
            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo * 10}  //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />
            }
        </div >
    )
}

export default PlanUserActivity
