import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select';
import ApiErrorMessage from '../../../hooks/apiErrorMessage'
import { toast } from 'react-toastify'
import GlobalProperties from '../../../routing/GlobalProperties'
import { UrlType } from '../../../routing/UrlType'

const detailBreadCrumbs: Array<PageLink> = [
    {
        title: 'Campaign',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Upload CSV',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const userInitialValue = {
    userType: "",
    csvFile: "",
    userType_error: "",
    csvFile_error: "",
}

type UserSubmitForm = {
    EmployeeName: string,
    EmployeeCode: string,
    designation: string,
    role: string,
    mobileNumber: number,
    selectstatus: string,
    userName: string,
    password: string

}

const userTypes = [
    { value: '', label: 'Select User Type' },
    { value: 'REG', label: 'Half' },
    { value: 'FULL', label: 'Full' }
]

const UploadCsv: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [details, setDetails] = useState<Object[]>([]);
    const [userInputData, setUserInputData] = useState<typeof userInitialValue>(userInitialValue)
    const [loading, setLoading] = useState<Boolean>(false);

    useEffect(() => {
        setFile(null);
    }, [])

    const getToken = () => {
        const user: any = localStorage.getItem('kt-auth-react-v') || null;
        const data: any = JSON.parse(user)
        return data.token;
    }
    const campaign_UploadCsv = async () => {
        const data: any = userInputData;
        try {
            if (validationHandler()) {
                console.log("ca;;")

                if (!file) {
                    alert('No file selected.');
                    //setError('No file selected.');
                    return;  // Exit early if no file is selected
                }
                const formData = new FormData();
                // Append the file to the formData
                formData.append('file', file);



                setLoading(true)
                const requestUrl = 'api/admin/v1/campaign/users/'
                let url = '';
                if (GlobalProperties.environment === UrlType.LIVE) {
                    url = GlobalProperties.urlParam + requestUrl;
                } else if (GlobalProperties.environment === UrlType.TEST) {
                    url = GlobalProperties.testParam + requestUrl;
                } else {
                    url = GlobalProperties.localUrlParam + requestUrl;
                }
                const response = await fetch(url + userInputData.userType, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Authorization': 'Bearer ' + getToken(),
                        'Custom-Header': 'custom-value'
                    },
                });

                if (response.ok) {
                    const jsonResponse = await response.json();
                    //setUploadSuccess(true);
                    console.log('File uploaded successfully', jsonResponse);
                    toast.success(jsonResponse.message);
                    window.location.reload();

                } else {
                    setLoading(false);
                    const jsonResponse = await response.json();
                    console.log(jsonResponse);
                    if (response?.status === 500 || response?.status === 503) {
                        toast.error("Something went wrong on server");
                    }
                    else if (response.status === 400) {
                        if (jsonResponse.error) {
                            console.log(jsonResponse.error);
                            const subCode: number = parseInt(jsonResponse.error.subcode);
                            const error = await ApiErrorMessage(subCode, jsonResponse.error.code);
                            console.log(error);
                            toast.error(error)
                        }
                        else if (jsonResponse.data) {
                            console.log("ssss:", jsonResponse.data[0]);

                            setDetails(jsonResponse.data)
                        }
                        //setUploadSuccess(false);
                        console.error('File upload failed', jsonResponse);
                    }
                    else {
                        toast.error("Something went wrong!!");
                    }

                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    const validationHandler = () => {
        if (userInputData?.userType === "" && userInputData?.csvFile === "") {
            setUserInputData((prev: any) => ({ ...prev, userType_error: 'Please Select User Type', csvFile_error: 'Please Select CSV File' }))
            return false;
        }
        else {
            if (userInputData?.userType === "") {
                setUserInputData((prev: any) => ({ ...prev, userType_error: 'Please Select User Type' }))
                return false
            }
            if (userInputData?.csvFile === "") {
                setUserInputData((prev: any) => ({ ...prev, csvFile_error: 'Please Select CSV File' }))
                return false
            }
        }
        return true;
    }



    const dataChangeHandler = (e: any) => {
        if (e.target.type === 'file') {

            if (e.target.files.length > 0) {
                const file = e.target.files[0]
                setFile(file)
                setUserInputData((prev: any) => ({ ...prev, csvFile: file }))
            }
        }

        setUserInputData((prev: any) => ({ ...prev, csvFile_error: '' }))
    }

    const { handleSubmit, control } = useForm<UserSubmitForm>({ mode: 'onChange' });

    const userTypeHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setUserInputData({ ...userInputData, userType: event.value, userType_error: '' });
        }
        else {
            setUserInputData({ ...userInputData, userType: '', userType_error: '' });
        }
    }

    return (
        <div>
            <PageTitle breadcrumbs={detailBreadCrumbs}>Upload CSV</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>
                    <div className="col-lg-3">
                        <label className='form-label'>Status</label>
                        <Controller
                            name='selectstatus'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isClearable
                                    isSearchable={true}
                                    placeholder='Select User Type'
                                    value={userTypes.filter(
                                        (obj) => obj.value === userInputData.userType
                                    )}
                                    onChange={userTypeHandler}
                                    id='userType'
                                    options={userTypes}
                                    className={userInputData?.userType_error ? 'error-border' : ''}
                                />
                            )}
                        />
                        <p id='error_1' className='errormsg' >{userInputData?.userType_error}</p>
                    </div>

                    <div className="col-lg-3">
                        <label className='form-label'>Upload CSV file</label>
                        <input
                            // disabled={true}
                            onChange={dataChangeHandler}
                            name="csvFile"
                            type="file"
                            accept=".csv"
                            className={`form-control ${userInputData?.csvFile_error === "" ? '' : 'is-invalid'}`}
                        />
                        <p className='errormsg' >{userInputData?.csvFile_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3 mt-8">
                        <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={campaign_UploadCsv}
                        >
                            {!loading && 'Submit'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {/* {details?.length > 0 ?
                    <div className="mb-3 col-lg-12 text-lg-center">
                        <button onClick={downloadExcelSheet} className="btn btn-primary clear-btn ms-0">Download Excel Sheet</button>
                    </div> : null
                } */}
            </div>
            {details.length > 0 &&
                <>
                    {/* begin::Header */}
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-3 mb-1'> MIS Search Details</span>
                        </h3>


                        {/* end::Header */}
                        {/* begin::Body */}

                        <div className='card mb-5'>
                            <div className='card-body py-3'>

                                <div className='table-responsive'>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        <thead>
                                            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                                <th className='min-w-100px'>Row No</th>
                                                <th className='min-w-100px'>Filed</th>
                                                <th className='min-w-150px'>Error Message</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {details.map((item: any, key: number) => (

                                                item.errorMessage && (

                                                    item.errorMessage.map((error: any, errorIndex: number) =>
                                                        <tr key={key}>
                                                            {errorIndex === 0 ?
                                                                <td rowSpan={item.errorMessage.length}>
                                                                    {item?.rowNumber}
                                                                </td>
                                                                :
                                                                <td style={{ display: "none" }}></td>
                                                            }
                                                            <td>{error.field}</td>
                                                            <td>{error.message}</td> {/* 
                                                        Combine messages into one cell */}
                                                        </tr>
                                                    )
                                                )))
                                            }
                                        </tbody>
                                    </table>
                                </div >
                            </div >
                        </div >
                    </div>
                </>
            }

        </div >
    )
}

export default UploadCsv