import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import CallFor from '../../../routing/CallFor';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Pagination from '../../../components/Pagination';
import Loading from '../components/Loading';
import { KTSVG } from '../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom'
import { convertResponsetojsonBig } from '../../../../_metronic/layout/core/convertToBigJson';

const userInitialValue = {
    mobileNo: "",
    campaignId: "",
    campaignStatus: 0,
    mobileNo_error: "",
    campaignId_error: "",
    campaignStatus_error: "",

}
type CampaignSubmitForm = {
    mobileNo: string,
    campaignId: string,
    campaignStatus: string,
}

const CampaignSearch: React.FC = () => {
    const navigate = useNavigate()

    const [details, setDetails] = useState<Object[]>([]);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<Boolean>(false);
    const [userInputData, setUserInputData] = useState<typeof userInitialValue>(userInitialValue)
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalNoOfData, setTotalNoOfData] = useState<number>(0);

    const CampaignStatusOptions = [
        { value: 1, label: 'ACTIVE' },
        { value: 2, label: 'DEACTIVE' },
    ]
    const {
        control
    } = useForm<CampaignSubmitForm>({})

    useEffect(() => {
        if (totalNoOfData > 0) {
            getUserSearchData({
                mobileNo: userInputData.mobileNo == "" ? null : userInputData.mobileNo,
                campaignId: userInputData.campaignId == "" ? null : userInputData.campaignId,
                campaignStatus: userInputData.campaignStatus == 0 ? null : userInputData.campaignStatus,
                page: pageNumber,

            })
        }

    }, [pageNumber])
    const convertBigIntToString = (bigIntNumber: any) => {
        return BigInt(bigIntNumber).toString()
    }

    const getUserSearchData = async (data: any) => {
        setUserInputData((prev: any) => ({
            ...prev, mobileNo_error: '', campaignId_error: '', campaignStatus_error: ''
        }))
        try {
            const apiResponse = await CallFor('api/admin/v1/campaign/users/list', 'POST', JSON.stringify(data), 'Auth')
            if (apiResponse.status === 200) {


                const jsonResponse = await convertResponsetojsonBig(apiResponse);
                if (jsonResponse.data?.content?.length === 0) {
                    setPageNumber(0);
                    setTotalNoOfData(0);
                }
                if (pageNumber === 0) {
                    setTotalNoOfData(Number(jsonResponse.data.pages))
                }

                setDetails(jsonResponse.data.content);
            }
        } catch (error) {
            setError(error);
        }
    }
    const validationHandler = () => {
        if (userInputData.mobileNo.trim() !== '') {
            if (userInputData.mobileNo.trim() === '') {
                setUserInputData((prev: any) => ({ ...prev, identificationNumber_error: 'Enter mobile number number' }))
                return true
            }
            else if (userInputData.mobileNo.trim().length < 10) {
                setUserInputData((prev: any) => ({ ...prev, mobileNo_error: 'Enter valid mobile number' }))
                return false
            }
            else if (userInputData.mobileNo.trim().length > 10) {
                setUserInputData((prev: any) => ({ ...prev, mobileNo_error: 'Enter valid mobile number' }))
                return false
            }
        }
        return true;
    }

    const searchHandler = () => {
        if (validationHandler()) {
            setPageNumber(0)
            getUserSearchData({
                mobileNo: userInputData.mobileNo == "" ? null : userInputData.mobileNo,
                campaignId: userInputData.campaignId == "" ? null : userInputData.campaignId,
                campaignStatus: userInputData.campaignStatus == 0 ? null : userInputData.campaignStatus,
                page: pageNumber,

            })
        }
    }
    const clearHandler = () => {
        setUserInputData(userInitialValue)
        setTotalNoOfData(0);
        setDetails([]);
    }

    const changeHandler = (e: any, isErrorHandler = true) => {

        if (isErrorHandler === true) {
            setUserInputData({ ...userInputData, [e.target.name]: e.target.value, [`${e.target.name}_error`]: "" })
        }
        else {
            setUserInputData({ ...userInputData, [e.target.name]: e.target.value })
        }
    }

    const UserSearchTypeHandler = (event: any) => {
        if (event !== null && event.value !== undefined) {
            setUserInputData({ ...userInputData, campaignStatus: event.value, campaignStatus_error: '' });
        }
        else {
            setUserInputData({ ...userInputData, campaignStatus: 0 });
        }
    }

    const campaignUploadCsv = () => {
        navigate('/campaign/UploadCsv')
    }

    return (
        <div>
            <PageTitle >Campaign Search</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>
                    <div className="col-lg-3">
                        <label className="form-label">Mobile No.</label>
                        <input
                            value={userInputData!.mobileNo}
                            onChange={(e) => changeHandler(e, true)}
                            name="mobileNo"
                            type="text"
                            className={`form-control ${userInputData.mobileNo_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Search Mobile Number"
                        />
                        <p className='errormsg' >{userInputData.mobileNo_error}</p>
                    </div>
                    <div className="col-lg-3">
                        <label className="form-label">Campaign Id</label>
                        <input
                            value={userInputData!.campaignId}
                            onChange={(e) => changeHandler(e, true)}
                            name="campaignId"
                            type="text"
                            className={`form-control ${userInputData.campaignId_error === "" ? '' : 'is-invalid'}`}
                            placeholder="Search Campaign Id"
                        />
                        <p className='errormsg' >{userInputData.campaignId_error}</p>
                    </div>
                    <div className="col-lg-3">
                        <label className='form-label'>Campaign Status</label>
                        <Controller
                            name='campaignStatus'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isClearable
                                    isSearchable={true}
                                    placeholder='Select Campaign Status'
                                    className={userInputData.campaignStatus_error ? 'error-border' : ''}
                                    value={CampaignStatusOptions.filter(
                                        (obj) => obj.value === userInputData.campaignStatus)}
                                    onChange={UserSearchTypeHandler}
                                    id="type"
                                    options={CampaignStatusOptions}
                                />
                            )} />
                        <p id='error_1' className='errormsg' >{userInputData?.campaignStatus_error}</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-4 ">
                        <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={searchHandler}
                        >Search</button>
                        <button type='submit' className='btn btn-danger clear-btn' onClick={clearHandler}>Clear</button>


                    </div>
                    {/* {details?.length > 0 ?
                        <div className="col-lg-6 text-lg-right text-lg-center">
                            <button onClick={downloadExcelSheet} className="btn btn-primary clear-btn ms-0">Download Excel Sheet</button>
                        </div> : null} */}
                </div>
            </div>

            {loading ? <Loading /> : <div className='card mb-5'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Search Details</span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Upload CSV'
                    >
                        <button type="button" className="btn btn-primary btn-sm" onClick={campaignUploadCsv} >Upload CSV</button>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {details?.length > 0 ?
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th>Id</th>
                                        <th className='min-w-150px'>User Name</th>
                                        <th className='min-w-150px'>Mobile No</th>
                                        <th className='min-w-120px'>User Status</th>
                                        <th className='min-w-120px'>Campaign Status</th>
                                        <th className='min-w-100px'>Date</th>
                                        {/* <th className='min-w-100px text-end'>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {details.map((item: any, key: number) =>
                                        <tr key={key}>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {item?.campaignId ? <>{
                                                        convertBigIntToString(item?.campaignId)}</> : "N/A"}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {item?.userName ? <>{item?.userName}</> : "N/A"}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {item?.mobileNo ? <>{item?.mobileNo}</> : "N/A"}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {item?.userStatus ? (item?.userStatus === "REG" ? "HALF" : <>{item?.userStatus}</>) : "N/A"}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {item?.campaignStatus ? <>{item?.campaignStatus}</> : "N/A"}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {item.createdOn || item?.createdOn ? <> {item.createdOn ? (item?.createdOn?.split('T')[0])?.split("-").reverse().join("-") : (item?.createdOn?.split('T')[0])?.split("-").reverse().join("-")}</> : "N/A"}
                                                </div>
                                            </td>
                                            {/* <td>
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                    <span
                                                        className="bg-transparent border-0"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="right"
                                                        data-bs-custom-class="tooltip-dark"
                                                        title="Create Company"
                                                    >
                                                        <a
                                                            href='#'
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        // onClick={() => { addCompany(item.id) }}
                                                        >

                                                            <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3' />
                                                            
                                                        </a>
                                                    </span>
                                                </div>
                                            </td> */}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        : <tbody>
                            <tr>
                                <td colSpan={9}>
                                    <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                                </td>
                            </tr>
                        </tbody>

                    }
                </div>
                {/* end::Table container */}

                {totalNoOfData > 0 &&
                    <Pagination
                        totalCount={totalNoOfData}  //total number of data
                        siblingCount={1} // number of extra elements around avtive page
                        currentPage={pageNumber} //current active page
                        onPageChange={setPageNumber} //page change handler state
                        pageSize={10} // data per page
                    />}
            </div>}
            {/* begin::Body */}
        </div >
    )
}

export default CampaignSearch