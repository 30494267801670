import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core'
import CallFor from '../../../routing/CallFor';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Pagination from '../../../components/Pagination';
import downloadimage from "../../../../_metronic/assets/images/download-svgrepo-com.svg";
const PaymentReport = () => {
    const hostname = window.location.hostname;
    const getToken = () => {
        const user: any = localStorage.getItem('kt-auth-react-v') || null;
        const data: any = JSON.parse(user)
        return data.token;
    }
    const [details, setDetails] = useState<Object[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPageNo, setTotalPageNo] = useState<number>(1);
    const [showData, setShowData] = useState<Boolean>(false);
    const [showDownloadBtn, setShowDownloadBtn] = useState<Boolean>(false);
    const [search, setSearch] = useState<any>({
        userName: '',
        userName_error: '',
        orderNumber: '',
        orderNumber_error: '',
        transactionId: '',
        transactionId_error: '',
        status: '',
        status_error: '',
        companyName: '',
        companyName_error: ''
    })
    const statusOption = [
        { value: "1", label: 'Pending' },
        { value: "2", label: 'Success' },
        { value: "3", label: 'Failure' },
    ]
    const searchHandler = async () => {
        try {
            let jsonData: any = {
                transactionId: search?.transactionId.trim() !== "" ? search?.transactionId.trim() : undefined,
                userName: search?.userName.trim() !== "" ? search?.userName.trim() : undefined,
                status: search?.status !== "" ? search?.status : undefined,
                invoiceNo: search?.orderNumber.trim() !== "" ? search?.orderNumber.trim() : undefined,
                page: page,
                companyName: search?.companyName.trim() !== "" ? search?.companyName.trim() : undefined,
            }
            const response = await CallFor('api/admin/v1/payment-report', 'POST', JSON.stringify(jsonData), 'Auth');

            if (response.status === 200) {
                const jsonResponse = await response.json();
                if (search?.status === '2') {
                    setShowDownloadBtn(true);
                } else {
                    setShowDownloadBtn(false);
                }
                if (jsonResponse.length === 0) {
                    setPage(0);
                    setTotalPageNo(0);
                }
                setTotalPageNo(Number(jsonResponse?.data?.page))
                setDetails(jsonResponse?.data?.content);
            } else if (response.status === 400) {

                toast.error("No Data Found")
                setDetails([]);
                setTotalPageNo(0);
            }
        } catch (error) {
            console.log(error);
        } finally {
        }
    };
    useEffect(() => {
        if (showData) {
            searchHandler()
        }
    }, [page])

    const clearHandler = () => {
        setDetails([]);
        setTotalPageNo(0);
        setSearch((prev: any) => ({
            userName: '',
            userName_error: '',
            orderNumber: '',
            orderNumber_error: '',
            transactionId: '',
            transactionId_error: '',
            status: '',
            status_error: '',
            companyName: '',
            companyName_error: '',
        }))
    }

    const validationSchema = () => {
        if (search.userName === '' && search.orderNumber === '' && search.transactionId === '' && search.status === '' && search.companyName === '') {
            setSearch((prev: any) => ({ ...prev, userName_error: "Enter UserName", orderNumber_error: "Enter Order Number", transactionId_error: "Enter Transaction ID", status_error: "Enter status", companyName_error: "Enter Company Name" }))
            return false
        }
        return true
    }

    const searchSubmit = () => {
        if (validationSchema()) {
            setPage(0);
            setShowData(true);
            searchHandler()
        }
    }

    const handleSearch = (event: any) => {
        const validationField = [event.target.name] + '_error'
        setSearch({ ...search, [event.target.name]: event.target.value, [validationField]: '' })
        setSearch((prev: any) => ({ ...prev, userName_error: "", orderNumber_error: "", transactionId_error: "", status_error: "" }))
    }
    const statusHandler = (e: any) => {
        setSearch({ ...search, status: e.value, status_error: "", userName_error: "", orderNumber_error: "", transactionId_error: "" })
    }
    const downloadInvoice = async (id: string, invoice: string) => {
        try {
            const response = await fetch(`${(hostname === "https://admin.zyapaar.com" || hostname === "admin.zyapaar.com") ? 'https://zyapaar.com/' : 'http://192.168.7.41:7777/'}pay/invoice/${id}`, {
                method: 'GET',
                headers: { "Authorization": 'Bearer ' + getToken() }
            });
            if (response.status === 200) {
                const fileName: any = `${invoice}.pdf`;
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            } else if (response.status === 400) {

            }
        } catch (error) {
            console.log(error);
        } finally {
        }
    }

    return (
        <div>
            <PageTitle >Payment Report</PageTitle>
            <div className='card mb-5 p-5'>
                <div className='row'>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">User Name</label>
                        <input
                            name='userName'
                            type="text"
                            className={`form-control ${search?.userName_error === "" ? '' : 'is-invalid'}`}
                            value={search.userName}
                            placeholder="User Name"
                            onChange={(e) => handleSearch(e)}
                        />
                        <p id='error_1' className='errormsg' >{search?.userName_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Invoice Number</label>
                        <input
                            name='orderNumber'
                            type="text"
                            className={`form-control ${search?.orderNumber_error === "" ? '' : 'is-invalid'}`}
                            value={search.orderNumber}
                            placeholder="Invoice Number"
                            onChange={(e) => handleSearch(e)}
                        />
                        <p id='error_1' className='errormsg' >{search?.orderNumber_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Transaction Id</label>
                        <input
                            // ref={transactionIdRef}
                            name='transactionId'
                            type="text"
                            placeholder="Transaction Id"
                            className={`form-control ${search?.transactionId_error === "" ? '' : 'is-invalid'}`}
                            value={search.transactionId}
                            onChange={(e) => handleSearch(e)}
                        />
                        <p id='error_1' className='errormsg' >{search?.transactionId_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">status</label>
                        <Select
                            placeholder='Select Status'
                            className={`${search?.status_error === "" ? '' : 'error-border'}`}
                            id='status'
                            options={statusOption}
                            value={statusOption.filter(
                                (obj) => obj?.value === search?.status)}
                            onChange={statusHandler}
                        />
                        <p id='error_1' className='errormsg' >{search?.status_error}</p>
                    </div>
                    <div className="mb-3 col-lg-3">
                        <label className="form-label">Company Name</label>

                        <input
                            name='companyName'
                            type="text"
                            className={`form-control ${search?.companyName_error === "" ? '' : 'is-invalid'}`}
                            value={search.companyName}
                            placeholder="Company Name"
                            onChange={(e) => handleSearch(e)}
                        />
                        <p id='error_1' className='errormsg' >{search?.companyName_error}</p>
                    </div>
                    <div className="col-lg-12  text-center">
                        <button type='submit' className='btn btn-primary' onClick={searchSubmit}>Search</button>
                        <button type='submit' className='btn btn-danger clear-btn' onClick={clearHandler}>Clear</button>
                    </div>
                </div>
            </div>


            <div className='card mb-5'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Payment Report Details</span>
                    </h3>
                </div>
                {<div className='card-body py-3'>
                    {details.length > 0 ?
                        <div className='table-responsive'>
                            <table className='table table-row-gray-300 align-middle gs-0 '>
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th className='min-w-120px'>Plan Date</th>
                                        <th className='min-w-150px'>Full Name</th>
                                        <th className='min-w-120px'>Plan Name</th>
                                        <th className='min-w-140px'>Invoice Number</th>
                                        <th className='min-w-120px'>Transaction Number </th>
                                        <th className='min-w-120px'>Payable Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        details?.map((detail: any, i: number) => <tr key={i}>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.createdOn}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.fullName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.planName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    <div className='mt-1'>
                                                        {detail?.orderNo}
                                                    </div>
                                                    {detail?.orderNo &&
                                                        <img height={25} width={45} src={downloadimage} alt="Button Image" className="cursor-pointer" onClick={() => downloadInvoice(detail?.id, detail?.orderNo)} />
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center gap-5'>
                                                    {detail?.id}

                                                </div>
                                            </td>

                                            <td>
                                                <div className='d-flex   align-items-center'>
                                                    {detail?.payableAmount}
                                                </div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : <tbody>
                            <tr>
                                <td colSpan={9}>
                                    <h6 className='text-center bg-gray-200i p-4 text-gray-800 mb-0 mt-6'>Data Not Found</h6>
                                </td>
                            </tr>
                        </tbody>
                    }
                </div>
                }
            </div>
            {
                totalPageNo > 0 &&
                <Pagination
                    totalCount={totalPageNo * 10}  //total number of data
                    siblingCount={1} // number of extra elements around avtive page
                    currentPage={page} //current active page
                    onPageChange={setPage} //page change handler state
                    pageSize={10} // data per page
                />
            }
        </div >
    )
}

export default PaymentReport