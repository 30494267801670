function numberStringToIdArray(str: any) {
    if (str !== null || str !== 'undefined') {
        console.log("str : " + str);

        str = str.toString().replace(/,{3}/g, ",");
        //console.log("str 22: " + str);

        // Split the string by commas and trim whitespace
        const items = str.split(',').map((item: any) => item.trim());

        //console.log("items : " + items);
        // Map each item to an object with id property
        const result = items.map((item: any) => ({ id: item }));
        //console.log("str result: ", result);
        return result;
    }
    else {
        return str;
    }
}

export default numberStringToIdArray;
